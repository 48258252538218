<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.id') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.id }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.keyAttribute') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.keyAttribute ? props.detail.keyAttribute.label : '-' }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('productTransformationLookupLink.detail.valueAttribute') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.valueAttribute ? props.detail.valueAttribute.label : '-' }}
    </vl-properties-data>
  </vl-properties-list>
  
</template>

<script lang="ts" setup>
import { ProductTransformationLookupLinkDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: ProductTransformationLookupLinkDetailDto;
}>();
</script>
