import { createInstance, dateReviver } from '@/api';
import { DeliveryEntityLinkClient, DeliveryEntityLinkDetailDto } from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, useAlertStore, VerticalPosition } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';

export interface DeliveryEntityLinkStoreState {
    items?: { key: number; value: DeliveryEntityLinkDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new DeliveryEntityLinkClient('', createInstance(), dateReviver);

export const useDeliveryEntityLinkStore = defineStore('delivery-entity-link-store', {
    state: (): DeliveryEntityLinkStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {},
    actions: {
        async getBySpecification(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);

            try {
                const response = await client.getBySpecification(id);

                const items = this.items.filter((x) => x.key !== id);
                if (response?.length) {
                    this.items = [...items, { key: id, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('entitylink.detail.error.title'),
                    i18n.global.t('entitylink.detail.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async createEntity(
            specificationId: number,
            fromEntityId: number,
            toEntityId: number,
            fromAttributeId: number,
            toAttributeId: number,
        ) {
            this.reloading = true;

            try {
                await client.createEntityLink({
                    specificationId,
                    fromEntityId,
                    toEntityId,
                    fromAttributeId,
                    toAttributeId,
                });

                await this.getBySpecification(specificationId, true);

                useAlertStore().setSuccess(
                    i18n.global.t('entitylink.create.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('entitylink.create.error.title'),
                    i18n.global.t('entitylink.create.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        async updateEntity(
            specificationId: number,
            deliveryEntityLinkId: number,
            fromEntityId: number,
            toEntityId: number,
            deliveryEntityAttributeLinkId: number,
            fromAttributeId: number,
            toAttributeId: number,
        ) {
            this.reloading = true;

            try {
                await client.updateEntityLink(deliveryEntityLinkId, {
                    deliveryEntityAttributeLinkId,
                    fromEntityId,
                    toEntityId,
                    fromAttributeId,
                    toAttributeId,
                });

                await this.getBySpecification(specificationId, true);

                useAlertStore().setSuccess(
                    i18n.global.t('entitylink.update.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('entitylink.update.error.title'),
                    i18n.global.t('entitylink.update.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        async deleteEntity(specificationId: number, deliveryEntityLinkId: number) {
            this.reloading = true;

            try {
                await client.deleteEntityLink(deliveryEntityLinkId);
                await this.getBySpecification(specificationId, true);

                useAlertStore().setSuccess(
                    i18n.global.t('entitylink.delete.success.title'),
                    null,
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } catch {
                useAlertStore().setError(
                    i18n.global.t('entitylink.delete.error.title'),
                    i18n.global.t('entitylink.delete.error.content'),
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(id: number) {
            this.items = this.items.filter((x) => x.key !== id);
        },
    },
});
