import { OrganisationClient, OrganisationSearchResultDto } from '@/api/portal-api/clients';
import { createInstance, dateReviver } from '@/api';
import { defineStore } from 'pinia';

export interface OrganisationStoreState {
    organisations?: OrganisationSearchResultDto[];
    loading: boolean;
}

export const client = new OrganisationClient('', createInstance(), dateReviver);

export const useOrganisationStore = defineStore('configuration-organisation-store', {
    state: (): OrganisationStoreState => ({
        organisations: [],
        loading: false,
    }),
    getters: {
        filter(): (query: string) => OrganisationSearchResultDto[] {
            return (query) => {
                if (!query) {
                    return this.organisations;
                } else {
                    return this.organisations?.filter(
                        (x) => x.name?.includes(query) || x.ovoNumber?.includes(query) || x.kboNumber?.includes(query),
                    );
                }
            };
        },
    },
    actions: {
        async getOrganisations() {
            this.loading = true;

            if (!this.organisations?.length) {
                try {
                    const response = await client.search(null, null, null, null, null, null, 1, 1000);
                    this.organisations = response.items;
                } catch (error) {
                    console.error(error);
                }
            }

            this.loading = false;
            return this.organisations;
        },
    },
});
