import Vue, { reactive } from 'vue';

import { ApiException, clients, DeliveryRun, DeliveryProcessDefinition, IDeliveryRunClient } from '@/api';
import { stores } from '@/store';
import { IDeliveryProcessDefinitionStore } from '@/store/deliveryProcessDefinition.store';

export function getDate(value: Date): Date {
    value && value.setHours(0, 0, 0, 0);
    return value;
}

export interface CreateDelivery {
    isFirstTime?: boolean;
    versionDate?: Date;
    versionDescription?: string;
    file?: File;
    definition?: DeliveryProcessDefinition;
    temporalLimitationFrom?: Date;
    temporalLimitationTo?: Date;
}

export class UploadError extends Error {
    private _originalError: Error;

    public get originalError(): Error {
        return this._originalError;
    }

    /**
     *
     */
    constructor(originalError: Error) {
        super();
        this.name = 'UploadError';
        this._originalError = originalError;
    }
}

export class DeliveryCreateService {
    private _processDelivery: DeliveryRun | undefined;

    public state = reactive({ delivery: {}, uploadProgress: 0, loading: false });

    constructor(
        private deliveryClient: IDeliveryRunClient,
        private definitionStore: () => IDeliveryProcessDefinitionStore,
    ) {}

    get definitions(): DeliveryProcessDefinition[] {
        return this.definitionStore().definitions;
    }

    get delivery(): CreateDelivery {
        return this.state.delivery;
    }

    get uploadProgress(): number {
        return this.state.uploadProgress;
    }

    get loading(): boolean {
        return this.state.loading;
    }

    get deliveryCreated(): boolean {
        return !!this._processDelivery;
    }

    setVersionDate(versionDate: Date): void {
        this.state.delivery = { ...this.delivery, versionDate };
    }
    setFirstTime(isFirstTime: boolean): void {
        this.state.delivery = { ...this.delivery, isFirstTime };
    }
    setDescription(versionDescription: string): void {
        this.state.delivery = { ...this.delivery, versionDescription };
    }
    setFile(file: File): void {
        this.state.delivery = { ...this.delivery, file };
    }
    setDefinition(definitionId?: number): void {
        this.state.delivery = {
            ...this.delivery,
            definition: this.definitions.find((p) => p.id == definitionId) || undefined,
        };
    }
    setTemporalLimitationFrom(temporalLimitationFrom?: Date): void {
        this.state.delivery = { ...this.delivery, temporalLimitationFrom };
    }
    setTemporalLimitationTo(temporalLimitationTo?: Date): void {
        this.state.delivery = { ...this.delivery, temporalLimitationTo };
    }

    setDelivery(payload: CreateDelivery): void {
        this.setDefinition(payload.definition?.id);
        this.setFirstTime(payload.isFirstTime);
        this.setVersionDate(payload.versionDate);
        this.setDescription(payload.versionDescription);
        this.setTemporalLimitationFrom(payload.temporalLimitationFrom);
        this.setTemporalLimitationTo(payload.temporalLimitationTo);
        this.setFile(payload.file);
    }

    async create(delivery: CreateDelivery): Promise<DeliveryRun> {
        this.setDelivery(delivery);
        this.state.loading = true;
        try {
            if (this._processDelivery?.id) {
                await this.deliveryClient.updateMetadata(this._processDelivery?.id, {
                    ...this.delivery,
                });
            } else {
                this._processDelivery = await this.deliveryClient.createDelivery({
                    definitionId: this.delivery.definition?.id,
                    ...this.delivery,
                });
            }
            await this.uploadDelivery();
            this.state.loading = false;
            return this._processDelivery;
        } catch (e) {
            this.state.loading = false;
            throw e;
        }
    }

    async cancel(): Promise<void> {
        if (this._processDelivery?.id) {
            return await this.deliveryClient.cancel(this._processDelivery?.id);
        }
        return;
    }

    async uploadDelivery(): Promise<void> {
        if (this._processDelivery?.id) {
            this.state.uploadProgress = 0;
            try {
                await this.deliveryClient.upload(
                    this._processDelivery?.id,
                    { data: this.delivery.file, fileName: this.delivery.file?.name || '' },
                    {
                        onUploadProgress: (e: { loaded: number; total?: number }) =>
                            (this.state.uploadProgress = Math.round((e.loaded * 100) / e.total)),
                    },
                );
            } catch (e) {
                if (e instanceof ApiException) {
                    if ((e as ApiException).status === 400 || (e as ApiException).status === 413) {
                        throw e;
                    }
                }
                throw new UploadError(e as Error);
            }
        }
    }

    async init(): Promise<void> {
        this._processDelivery = undefined;
        await this.definitionStore().fetchDefinitions();
        this.state.delivery = {
            versionDate: getDate(new Date()),
            temporalLimitationFrom: getDate(new Date()),
        };
        if (this.definitions.length == 1) {
            this.setDefinition(this.definitions[0].id);
        }
    }
}

export const DeliveryCreateServiceInstance = new DeliveryCreateService(
    clients.DeliveryRunClient,
    stores.DeliveryProcessDefinitionStore,
);
