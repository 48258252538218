<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="transformationType"
        :label="t('deliverytransformation.detail.transformationType')"
        :placeholder="$t('deliverytransformation.detail.transformationType')"
        :options="transformationTypes"
        :custom-label="(e) => e.name"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromEntity"
        :label="t('deliverytransformation.detail.fromEntity')"
        :placeholder="$t('deliverytransformation.detail.fromEntity')"
        :options="fromEntityOptions"
        :custom-label="fromEntityLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toEntity"
        :label="t('deliverytransformation.detail.toEntity')"
        :placeholder="$t('deliverytransformation.detail.toEntity')"
        :options="toEntityOptions"
        :custom-label="toEntityLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="parameter"
        :label="t('deliverytransformation.detail.parameter')"
        :placeholder="t('deliverytransformation.detail.parameter')"
      />
    </vl-form-column>
    <vl-form-column v-if="canAddAttributeMapping" v-vl-spacer:bottom.small>
      <DeliveryTransformationAttributesEdit
        :definition-id="definitionId"
        :from-entity-id="fromEntityId"
        :to-entity-id="toEntityId"
      />
    </vl-form-column>
    <PbsErrorField name="custom-validation" />
    <vl-action-group v-vl-spacer:bottom.small>
      <vl-button icon="save" :mod-loading="reloading" mod-icon-before type="submit">
        {{ $t('general.save') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useDeliveryTransformationStore } from '@/modules/configuration/store/delivery-transformation/delivery-transformation.store';
import { useDeliveryProcessDefinitionEditStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import { EnumerationDto } from '@/api/portal-api/clients';
import { ENUM_TransformationTypes } from '@/modules/configuration/common';
import { useDeliveryEntityStore } from '@/modules/configuration/store/delivery-entity/delivery-entity.store';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import { DeliveryEntityDetailDto } from '@/api/portal-api/clients';
import { OperationEntityDetailDto } from '@/api/portal-api/clients';
import DeliveryTransformationAttributesEdit from '../delivery-transformation/DeliveryTransformationAttributesEdit.vue';
import { useDeliveryTransformationValidations } from './delivery-transformation-validations';
import { isAttributeMappingSupported } from './delivery-transformation-utils';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsErrorField from '@/components/forms/PbsErrorField.vue';

const { t } = useI18n();

const store = useDeliveryTransformationStore();
const editStore = useDeliveryProcessDefinitionEditStore();
const deliveryEntityStore = useDeliveryEntityStore();
const operationEntityStore = useOperationEntityStore();

const props = defineProps({
  definitionId: {
    type: Number,
    required: true,
  },
});

const { validationSchema, transformationTypeRef } = useDeliveryTransformationValidations(t);

const { handleSubmit, values, setValues } = useForm({
  validationSchema,
});

const isAttributeMappingType = computed(() => {
  return isAttributeMappingSupported(transformationType.value);
});

const transformationTypes = computed((): EnumerationDto[] => {
  return ENUM_TransformationTypes;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const fromEntityOptions = computed(() => {
  const key = `definition-${props.definitionId}`;
  return deliveryEntityStore.items.find((x) => x.key === key)?.value ?? [];
});

const toEntityOptions = computed(() => {
  const key = `definition-${props.definitionId}`;
  return operationEntityStore.items.find((x) => x.key === key)?.value ?? [];
});

const fromEntityId = computed(() => {
  return values.fromEntity?.id ?? 0;
});

const toEntityId = computed(() => {
  return values.toEntity?.id ?? 0;
});

const transformationType = computed(() => {
  return values.transformationType;
});

const attributeMappings = computed(() => {
  return values.attributeMappings ?? [];
});

const canAddAttributeMapping = computed(() => {
  return fromEntityId.value && toEntityId.value && isAttributeMappingType.value;
});

const fromEntityLabel = (deliveryEntity: DeliveryEntityDetailDto) => {
  return `${deliveryEntity.code} (${deliveryEntity.label})`;
};

const toEntityLabel = (operationEntity: OperationEntityDetailDto) => {
  return `${operationEntity.code} (${operationEntity.label})`;
};

watch(fromEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const fromEntity = fromEntityOptions.value.find((x) => x.id === newValue);
  if (fromEntity) {
    updateFromAttributeValues(fromEntity);
  }
});

watch(toEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const toEntity = toEntityOptions.value.find((x) => x.id === newValue);
  if (toEntity) {
    updateToAttributeValues(toEntity);
  }
});

watch(transformationType, (newValue, oldValue) => {
  // update the transformation type reference for validation schema
  transformationTypeRef.value = newValue;
});

onMounted(async () => {
  await Promise.all([
    deliveryEntityStore.getByDefinition(props.definitionId),
    operationEntityStore.getByDefinition(props.definitionId),
  ]);
});

const updateFromAttributeValues = (fromEntity: DeliveryEntityDetailDto) => {
  const fromAttributes = fromEntity.model?.attributes;
  if (!fromAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const fromAttribute = fromAttributes.find((x) => x.id === mapping.from?.id);
    if (!fromAttribute) {
      mapping.from = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues(
      {
        ...values,
        attributeMappings: updatedAttributeMappings,
      },
      true,
    );
  }
};

const updateToAttributeValues = (toEntity: OperationEntityDetailDto) => {
  const toAttributes = toEntity.model?.attributes;
  if (!toAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const toAttribute = toAttributes.find((x) => x.id === mapping.to?.id);
    if (!toAttribute) {
      mapping.to = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues({
      ...values,
      attributeMappings: updatedAttributeMappings,
    });
  }
};

const createAttributeMappingsToUpdate = () => {
  if (!isAttributeMappingType.value) {
    return [];
  }

  return values.attributeMappings.map((x) => ({
    id: x.id,
    fromAttributeId: x.from.id,
    toAttributeId: x.to.id,
  }));
};

const onSuccess = async (values) => {
  const attributeMappings = createAttributeMappingsToUpdate();

  const createDto = {
    deliveryProcessDefinitionId: props.definitionId,
    transformationType: values.transformationType.name,
    fromEntityId: values.fromEntity.id,
    toEntityId: values.toEntity.id,
    parameter: values.parameter,
    attributeMappings,
  };

  await store.create(props.definitionId, createDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
