<template>
  <vl-properties>
    <vl-properties-column mod-full>
      <vl-properties-list>
        <vl-properties-label>{{ $t('processtrigger.detail.id') }}</vl-properties-label>
        <vl-properties-data>{{ detail.id }}</vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.description') }}</vl-properties-label>
        <vl-properties-data>{{ detail.description }}</vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.triggerType') }}</vl-properties-label>
        <vl-properties-data>{{ detail.triggerType?.name }}</vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.schedule') }}</vl-properties-label>
        <vl-properties-data>{{ detail.schedule ? detail.schedule : `-` }}</vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.deliveryProcessDefinition') }}</vl-properties-label>
        <vl-properties-data>
          <router-link
            v-if="detail.deliveryProcessDefinition"
            :to="'/configuration/deliveryprocessdefinition/' + detail.deliveryProcessDefinition.id"
            target="_blank"
          >
            {{ detail.deliveryProcessDefinition.label }}
          </router-link>
          <span v-else>-</span>
        </vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.organisation') }}</vl-properties-label>
        <vl-properties-data>
          <router-link
            v-if="detail.organisation"
            :to="'/configuration/organisation/' + detail.organisation.id"
            target="_blank"
          >
            {{ detail.organisation.name }}
          </router-link>
          <span v-else>-</span>
        </vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.job') }}</vl-properties-label>
        <vl-properties-data>{{ detail.job ? detail.job : `-` }}</vl-properties-data>
        <vl-properties-label>{{ $t('processtrigger.detail.isEnabled') }}</vl-properties-label>
        <vl-properties-data>{{ detail.isEnabled ? $t('general.yes') : $t('general.no') }}</vl-properties-data>
      </vl-properties-list>
    </vl-properties-column>
  </vl-properties>
</template>

<script lang="ts" setup>
import { ProcessTriggerDetailDto } from '@/api/portal-api/clients';
import { useProcessTriggerDetailStore } from '../../store/process-trigger/process-trigger-detail.store';
import { computed } from 'vue';

const store = useProcessTriggerDetailStore();

const detail = computed((): ProcessTriggerDetailDto => {
  return store.detail;
});
</script>

<style scoped lang="scss"></style>
