<template>
  <vl-title tag-name="h4"> {{ $t('deliverytransformation.detail.attributes') }} </vl-title>
  <vl-data-table-extended
    :data="items"
    :columns="columnMap"
    :meta="{
      totalRows: props.detail.attributeMappings?.length,
      resultsPerPage: props.detail.attributeMappings?.length,
      currentPage: 1,
    }"
    mod-zebra
    mod-hide-pagination
  >
    <template v-slot:noresults>
      <div v-vl-align:left>
        <div class="vl-u-spacer--large">
          <p>{{ $t('deliveryTransformationAttributes.detail.empty') }}</p>
        </div>
      </div>
    </template>
  </vl-data-table-extended>
</template>
<script lang="ts" setup>
import { computed, h } from 'vue';
import { DeliveryTransformationDetailDto } from '@/api/portal-api/clients';
import { useI18n } from 'vue-i18n';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';

const { t } = useI18n();
const props = defineProps<{
  detail: DeliveryTransformationDetailDto;
}>();

const items = computed(() => {
  return (
    props.detail.attributeMappings
      ?.map((f) => {
        return {
          from: f.fromAttribute ? `${f.fromAttribute.code} - ${f.fromAttribute.label}` : '/',
          to: f.toAttribute ? `${f.toAttribute.code} - ${f.toAttribute.label}` : '/',
        };
      })
      .sort((x, y) => (x.from > y.from ? 1 : -1)) ?? []
  );
});

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'from',
      header: t('deliverytransformation.detail.fromKey'),
      size: 50,
    },
    {
      accessorKey: 'to',
      header: t('deliverytransformation.detail.toKey'),
      size: 50,
    },
  ];
});
</script>
