<template>
  <vl-layout>
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('entityAttribute.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="code"
            :label="t('entityAttribute.detail.code')"
            :placeholder="t('entityAttribute.detail.code')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="label"
            :label="t('entityAttribute.detail.label')"
            :placeholder="t('entityAttribute.detail.label')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsTextAreaField
            name="description"
            :label="t('entityAttribute.detail.description')"
            :placeholder="t('entityAttribute.detail.description')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="order"
            :label="t('entityAttribute.detail.order')"
            :placeholder="t('entityAttribute.detail.order')"
            type="number"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="dataType"
            :label="t('entityAttribute.detail.dataType')"
            :placeholder="$t('entityAttribute.detail.dataType')"
            :options="dataTypes"
            :custom-label="enumerationLabel"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="length"
            :label="t('entityAttribute.detail.length')"
            :placeholder="t('entityAttribute.detail.length')"
            type="number"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="decimalCount"
            :label="t('entityAttribute.detail.decimalCount')"
            :placeholder="t('entityAttribute.detail.decimalCount')"
            type="number"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField name="isIdentifying" :label="$t('entityAttribute.detail.isIdentifying')" />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField name="isMandatory" :label="$t('entityAttribute.detail.isMandatory')" />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField name="isCalculated" :label="$t('entityAttribute.detail.isCalculated')" />
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="reloading" mod-icon-before type="submit">
            {{ $t('general.save') }}
          </vl-button>
          <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
            {{ $t('general.cancel') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { boolean, number, object, string } from 'yup';
import { computed } from 'vue';
import { EnumerationDto } from '@/api/portal-api/clients';
import { ENUM_DataTypes } from '@/modules/configuration/common';
import { useEntityAttributeStore } from '@/modules/configuration/store/entity-attribute/entity-attribute.store';
import { useEntityModelEditStore } from '@/modules/configuration/store/entity-model/entity-model-edit.store';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';

const { t } = useI18n();
const store = useEntityAttributeStore();
const editStore = useEntityModelEditStore();

const dataTypes = computed((): EnumerationDto[] => {
  return ENUM_DataTypes;
});

const validationSchema = object({
  code: string()
    .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.code') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('entityAttribute.detail.code'), length: 256 })),
  label: string()
    .required(t('validations.messages.required', { _field_: t('entityAttribute.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('entityAttribute.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('entityAttribute.detail.description') }),
  ),
  order: number().required(t('validations.messages.required', { _field_: t('entityAttribute.detail.order') })),
  dataType: object().required(t('validations.messages.required', { _field_: t('entityAttribute.detail.dataType') })),
  length: number().required(t('validations.messages.required', { _field_: t('entityAttribute.detail.length') })),
  decimalCount: string().optional(),
  isIdentifying: boolean(),
  isMandatory: boolean(),
  isCalculated: boolean(),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const props = defineProps({
  modelId: {
    type: Number,
    required: true,
  },
});

const onSuccess = async (values) => {
  await store.create({
    entityModelId: props.modelId,
    code: values.code,
    label: values.label,
    description: values.description,
    order: values.order,
    dataType: values.dataType.name,
    length: values.length,
    decimalCount: values.decimalCount,
    isIdentifying: values.isIdentifying,
    isMandatory: values.isMandatory,
    isCalculated: values.isCalculated,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
