import { createInstance, dateReviver } from '@/api';
import {
    ProductProcessDefinitionClient,
    OrderDirection,
    ProductProcessDefinitionSearchResultDto,
    OrganisationSearchResultDto,
} from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ProductProcessDefinitionFilter {
    id?: number | null;
    label?: string;
    description?: string;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
    organisation?: OrganisationSearchResultDto;
    statusType?: string;
}

export interface ProductProcessDefinitionStoreState {
    filter: ProductProcessDefinitionFilter;
    loading: boolean;
    items?: ProductProcessDefinitionSearchResultDto[];
    totalCount?: number;
}

export const client = new ProductProcessDefinitionClient('', createInstance(), dateReviver);

export const useProductProcessDefinitionListStore = defineStore('product-process-definition-list-store', {
    state: (): ProductProcessDefinitionStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
            organisation: null,
        },
        totalCount: 0,
        loading: false,
        items: [],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
        getDefinition(): (id: number) => ProductProcessDefinitionSearchResultDto | undefined {
            return (id: number) => this.items?.find((item) => item.id === id);
        },
    },
    actions: {
        init(filter: ProductProcessDefinitionFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
                organisation: null,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        resetPager() {
            this.filter.pageNumber = 1;
        },
        updateItem(item: ProductProcessDefinitionSearchResultDto) {
            const index = this.items?.findIndex((i) => i.id === item.id);
            if (index !== undefined && index !== -1) {
                this.items?.splice(index, 1, item);
            }
        },
        async getDefinitions() {
            this.loading = true;

            useAlertStore().clearAlert();

            const order = mapToQueryableOrder(this.filter.order);
            try {
                const response = await client.search(
                    this.filter.id,
                    this.filter.label,
                    this.filter.description,
                    null,
                    this.filter.organisation?.ovoNumber,
                    null,
                    this.filter.statusType,
                    order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                );

                this.items = response.items;
                this.filter.pageNumber = response.page;
                this.filter.pageSize = response.pageSize;
                this.totalCount = response.totalCount;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('productprocessdefinition.list.error.title'),
                    i18n.global.t('productprocessdefinition.list.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});

function mapToQueryableOrder(order: string) {
    switch (order) {
        case 'statusId':
            return 'status';
        case 'ovoNumber':
            return 'organisation.ovoNumber';
        case 'organisation':
            return 'organisation.name';
        default:
            return order;
    }
}
