<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('productentity.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.code') }}</vl-properties-label>
    <vl-properties-data>{{ detail.code }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.model') }}</vl-properties-label>
    <vl-properties-data>
      <router-link :to="'/configuration/entitymodel/' + detail.model.id" target="_blank">
        {{ detail.model.label }}
      </router-link>
    </vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.crs') }}</vl-properties-label>
    <vl-properties-data>{{ detail.crs.name }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ProductEntityDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: ProductEntityDetailDto;
}>();

const detail = computed((): ProductEntityDetailDto => {
  return props.detail;
});
</script>
