<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="10" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('operationentity.detail.title') }} </vl-title>
      </vl-column>
      <vl-column width="2" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button icon="plus" mod-icon-before :mod-disabled="isCreating" @click="onCreateButtonClick">{{
            $t('general.add')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: isCreating }">
      <vl-column>
        <OperationEntityCreate v-if="isCreating" :specification-id="specificationId" />
      </vl-column>
    </vl-grid>
    <vl-grid v-if="items && items.length" v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-accordion-list>
          <vl-accordion-list-item v-for="(entity, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(entity)">
              <vl-grid :class="{ edit: isEditing(entity.id) }">
                <vl-column width="8">
                  <OperationEntityEdit
                    v-if="isEditing(entity.id)"
                    ref="editForm"
                    :detail="entity"
                    :specification-id="specificationId"
                  />
                  <OperationEntityRead v-else :detail="entity" />
                </vl-column>
                <vl-column width="4">
                  <vl-action-group v-if="!isEditing(entity.id)" mod-align-right mod-space between>
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      :mod-disabled="editStore.editing"
                      @click="onEditButtonClick(entity.id)"
                    >
                      {{ $t('general.edit') }}
                    </vl-button>
                    <vl-button icon="trash" mod-icon-before mod-error @click="onDeleteButtonClick(entity.id)">{{
                      $t('general.delete')
                    }}</vl-button>
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before @click="onSaveButtonClick">{{
                      $t('general.save')
                    }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelSaveButtonClick">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
              </vl-grid>
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
      </vl-column>
    </vl-grid>
    <vl-grid v-else v-vl-spacer:bottom.medium>
      <vl-column>
        <vl-annotation>{{ $t('operationentity.detail.empty') }}</vl-annotation>
      </vl-column>
    </vl-grid>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('operationentity.detail.delete.title')"
      :open="editStore.isDelete(DeliveryProcessDefinitionEditType.OperationEntity, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="onConfirmRemoveButtonClick(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="onCancelRemoveButtonClick">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </template>
</template>
<script lang="ts" setup>
import OperationEntityRead from './OperationEntityRead.vue';
import OperationEntityCreate from './OperationEntityCreate.vue';
import OperationEntityEdit from './OperationEntityEdit.vue';
import { OperationEntityDetailDto } from '@/api/portal-api/clients';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import {
  DeliveryProcessDefinitionEditType,
  useDeliveryProcessDefinitionEditStore,
} from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import { computed, onMounted, Ref, ref } from 'vue';

const store = useOperationEntityStore();
const editStore = useDeliveryProcessDefinitionEditStore();

const editForm: Ref<typeof OperationEntityEdit | (typeof OperationEntityEdit)[]> = ref(null);

const props = defineProps({
  specificationId: {
    type: Number,
    required: true,
  },
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): OperationEntityDetailDto[] => {
  const key = `specification-${props.specificationId}`;
  return store.items?.find((x) => x.key === key)?.value ?? [];
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const isCreating = computed((): boolean => {
  return editStore.isCreate(DeliveryProcessDefinitionEditType.OperationEntity);
});

const isEditing = (entityId: number): boolean => {
  return editStore.isEdit(DeliveryProcessDefinitionEditType.OperationEntity, entityId);
};

onMounted(async () => {
  if (props.specificationId) {
    await store.getBySpecification(props.specificationId);
  }
});

const onCreateButtonClick = () => {
  editStore.openCreate(DeliveryProcessDefinitionEditType.OperationEntity);
};

const onEditButtonClick = (entityId: number) => {
  editStore.openEdit(DeliveryProcessDefinitionEditType.OperationEntity, entityId);
};

const onDeleteButtonClick = (entityId: number) => {
  editStore.openDelete(DeliveryProcessDefinitionEditType.OperationEntity, entityId);
};

const onSaveButtonClick = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const onCancelSaveButtonClick = () => {
  editStore.closeEdit();
};

const onConfirmRemoveButtonClick = async (entityId: number) => {
  await store.remove(props.specificationId, entityId);
  editStore.closeDelete();
};

const onCancelRemoveButtonClick = () => {
  editStore.closeDelete();
};

const getLabel = (item: OperationEntityDetailDto): string => {
  return `${item.code} - ${item.label}`;
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
.vl-grid {
  flex-grow: 1;
}
</style>
