import { computed, defineComponent, reactive, ref, watch, useSlots, PropType } from 'vue'
import {
  AccessorKeyColumnDef,
  Cell,
  Column,
  ColumnDef,
  FlexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  Row,
  RowData,
  useVueTable
} from '@tanstack/vue-table'
import VlDataTableExtendedPager from './subcomponents/data-table-extended-pager/vl-data-table-extended-pager.vue'
import { ColumnDefExtended, MetaExtended, TableOptionsExtended } from './types'

export default defineComponent({
  name: 'VlDataTableExtended',
  components: {
    FlexRender,
    VlDataTableExtendedPager
  },
  props: {
    fetching: {
      required: false,
      type: Boolean,
      default: false
    },
    data: {
      required: true,
      type: Array as PropType<RowData[]>,
      default: () => []
    },
    columns: {
      required: true,
      type: Array as PropType<ColumnDefExtended<RowData>[]>,
      default: () => []
    },
    meta: {
      required: false,
      type: Object as () => {
        totalRows: number
        resultsPerPage: number
        currentPage: number
        serverside: boolean
      },
      default: null
    },
    modHidePagination: {
      required: false,
      type: Boolean,
      default: false
    },
    modPaginationOnTop: {
      required: false,
      type: Boolean,
      default: false
    },
    modHideHeader: {
      required: false,
      type: Boolean,
      default: false
    },
    modClickableRows: {
      required: false,
      type: Boolean,
      default: false
    },
    modSelectableRows: {
      required: false,
      type: Boolean,
      default: false
    },
    modMultiSelectableRows: {
      required: false,
      type: Boolean,
      default: false
    },
    columnOnRowClick: {
      required: false,
      type: Boolean,
      default: false
    },
    modSticky: {
      required: false,
      type: Boolean,
      default: false
    },
    modStickyHeader: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['pager-clicked', 'checkbox-clicked', 'row-clicked', 'column-clicked'],
  setup(props, { emit }) {
    const DEFAULT_PAGE_SIZE = 25
    const meta = ref(props.meta)
    const sorting = ref([])
    const defaultSortedColumn = props.columns.find((c) => c.sortDescFirst !== undefined) as AccessorKeyColumnDef<RowData>
    if (defaultSortedColumn && (defaultSortedColumn.accessorKey || defaultSortedColumn.id)) {
      const id = defaultSortedColumn.id || defaultSortedColumn.accessorKey
      sorting.value = [
        {
          id: id,
          desc: defaultSortedColumn.sortDescFirst
        }
      ]
    }

    const tableOptions = reactive<TableOptionsExtended<RowData & { routerLink?: string }>>({
      get data(): any {
        return props.data || []
      },
      manualPagination: meta.value.serverside,
      autoResetPageIndex: !meta.value.serverside,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: meta.value.serverside ? undefined : getPaginationRowModel(),
      getSortedRowModel: meta.value.serverside ? undefined : getSortedRowModel(),
      enableSorting: true,
      enableSortingRemoval: false,
      enableRowSelection: props.modSelectableRows,
      enableRowClick: props.modClickableRows,
      enableMultiRowSelection: props.modMultiSelectableRows,
      state: {
        get sorting() {
          return sorting.value
        }
      },
      onSortingChange: (updaterOrValue) => {
        sorting.value = typeof updaterOrValue === 'function' ? updaterOrValue(sorting.value) : updaterOrValue
        if (meta.value.serverside) {
          emit('column-clicked', { key: sorting.value[0].id, direction: sorting.value[0].desc ? 'Desc' : 'Asc' })
        }
      },
      get columns(): any {
        return props.columns.map((column: any) => {
          if (!column.cell) {
            return {
              ...column,
              sortingFn: column.sortingFn ? column.sortingFn : 'auto',
              cell: ({ getValue }) => {
                return getValue === undefined ? '' : getValue()
              }
            }
          } else {
            return column
          }
        })
      }
    })
    const table = useVueTable(tableOptions)

    watch(
      () => props.meta,
      (newValue) => {
        if (table && newValue) {
          table.setOptions((prev: any) => {
            return {
              ...prev,
              get data() {
                return props.data || []
              },
              pageCount: newValue.serverside ? Math.ceil(newValue.totalRows / newValue.resultsPerPage) : undefined
            }
          })
          table.setPageSize(newValue.resultsPerPage || DEFAULT_PAGE_SIZE)
        }
      },
      { immediate: true, deep: true }
    )

    const currentPageIndex = computed(() => {
      return table.getState().pagination.pageIndex
    })

    const totalItems = computed(() => {
      return meta.value.serverside ? props.meta.totalRows : table.getFilteredRowModel().rows.length
    })

    const itemsOnPage = computed(() => {
      return table.getPaginationRowModel()?.rows?.length || 0
    })

    const hasNoResultsSlot = computed(() => {
      return useSlots().noresults !== undefined
    })

    function rowClasses(row: Row<any>) {
      return [
        {
          'vl-data-table__element--processing': row.original.type === 'processing',
          'vl-data-table__element--clickable': props.modClickableRows,
          'vl-data-table__element--error': row.original.error,
          'vl-data-table__element--success': row.original.success,
          'vl-data-table__element--info': row.original.info
        },
        `${row.original.class ? row.original.class : ''}`
      ]
    }

    function headerClasses() {
      return [
        {
          'vl-data-table__cell--sticky': props.modSticky && props.modStickyHeader
        }
      ]
    }

    function sortIcon(column: Column<any>) {
      if (column && column.getIsSorted()) {
        const columnDef = column.columnDef as ColumnDefExtended<any>
        return columnDef.type === 'Number'
          ? 'arrange-' + (column.getIsSorted() === 'asc' ? '1-to-9' : '9-to-1')
          : columnDef.type === 'String'
            ? 'arrange-' + (column.getIsSorted() === 'asc' ? 'a-to-z' : 'z-to-a')
            : column.getIsSorted() === 'asc'
              ? 'arrow-up'
              : 'arrow-down'
      }
      return ''
    }

    function goPage(page: number) {
      if (meta.value.serverside) {
        emit('pager-clicked', page)
      }
      table.setPageIndex(page - 1)
    }

    function cellClicked(cell: Cell<any, any>) {
      emit(
        'row-clicked',
        props.columnOnRowClick
          ? {
              row: cell.row.original,
              column: cell.column.columnDef
            }
          : cell.row.original
      )
    }

    function isColumnLink(columnDef: ColumnDef<any>) {
      return (columnDef as ColumnDefExtended<any>)?.link
    }

    function bindCellContext(cell: Cell<any, any>) {
      const ctx = cell.getContext()
      const meta = ctx.cell.column.columnDef.meta as MetaExtended
      if (meta) {
        return meta.getCellContext(ctx)
      }
      return null
    }

    return {
      table,
      totalItems,
      hasNoResultsSlot,
      currentPageIndex,
      itemsOnPage,
      DEFAULT_PAGE_SIZE,
      rowClasses,
      sortIcon,
      goPage,
      cellClicked,
      isColumnLink,
      headerClasses,
      bindCellContext
    }
  }
})
