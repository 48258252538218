<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#deliveryprocessdefinition" />
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('deliveryprocessdefinition.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="label"
            :label="t('deliveryprocessdefinition.create.label')"
            :placeholder="t('deliveryprocessdefinition.create.label')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsTextAreaField
            name="description"
            :label="t('deliveryprocessdefinition.create.description')"
            :placeholder="t('deliveryprocessdefinition.create.description')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="organisation"
            :label="t('deliveryprocessdefinition.create.organisation')"
            :placeholder="$t('deliveryprocessdefinition.create.organisation')"
            :options="organisationOptions"
            :custom-label="getLabel"
            @search-change="onSearchChange"
          />
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="loading" mod-icon-before type="submit"
            >{{ $t('general.save') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';
import { useDeliveryProcessDefinitionDetailStore } from '../../store/delivery-process-definition/delivery-process-definition-detail.store';
import { useRouter } from 'vue-router';
import { Ref, computed, onMounted, ref } from 'vue';
import { OrganisationSearchResultDto } from '@/api/portal-api/clients';
import { useOrganisationStore } from '../../store/organisation/organisation.store';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';

const { t } = useI18n();
const store = useDeliveryProcessDefinitionDetailStore();
const organisationStore = useOrganisationStore();
const router = useRouter();
const organisationOptions: Ref<OrganisationSearchResultDto[]> = ref([]);

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('deliveryprocessdefinition.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.description') }),
  ),
  organisation: object().required(
    t('validations.messages.required', { _field_: t('deliveryprocessdefinition.create.organisation') }),
  ),
});
const { handleSubmit } = useForm({
  validationSchema,
});

const loading = computed((): boolean => {
  return store.loading;
});

onMounted(async () => {
  organisationOptions.value = await organisationStore.getOrganisations();
});

const onSuccess = async (values) => {
  const id = await store.create({
    label: values.label,
    description: values.description,
    ovoNumber: values.organisation.ovoNumber,
  });

  if (id) {
    router.push({
      name: 'deliveryprocessdefinition.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchChange = async (query: string) => {
  organisationOptions.value = organisationStore.filter(query);
};

const getLabel = (entry: OrganisationSearchResultDto) => {
  return `${entry?.name} (${entry?.ovoNumber})`;
};

defineExpose({ onSubmit });
</script>
