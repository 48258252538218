import { ProductEntityDetailDto, ProductTransformationLookupLinkClient, ProductTransformationLookupLinkDetailDto, SourceEntityDetailDto, TransformationTypeDto } from '@/api/portal-api/clients';
import { ENUM_TransformationTypes } from '@/modules/configuration/common';

const attributeMappingTypes = ENUM_TransformationTypes.filter((type) => [2, 3, 4, 5, 6].includes(type.id));

export const isAttributeMappingSupported = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && attributeMappingTypes.some((type) => type.id === transformationType.id);
};


export const isEntityMappingType = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && transformationType.id === 1;
};

export const isLookupLinkType = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && transformationType.id === 3;
};

export function hasSameEntityModel(fromEntity: ProductEntityDetailDto, toEntity: SourceEntityDetailDto) {
    return fromEntity?.model?.id === toEntity?.model?.id;
}

export function getLookupOptions(fromEntity: SourceEntityDetailDto, links: ProductTransformationLookupLinkDetailDto[]): ProductTransformationLookupLinkDetailDto[] {
    if(!fromEntity) return links
    const attributes = fromEntity?.model?.attributes;
    const options = links?.filter((x) => attributes?.some((a) => a.code === x.keyAttribute.code));

    return options
}