<template>
  <FieldArray v-slot="{ fields, remove, prepend }" name="attributeMappings">
    <vl-grid v-vl-spacer:top.medium>
      <vl-column width="8" width-xs="12">
        <vl-title tag-name="h4"> {{ $t('deliverytransformation.detail.attributes') }} </vl-title>
      </vl-column>
      <vl-column width="4" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button type="button" icon="add" mod-icon @click="prepend({ from: null, to: null, id: null })"></vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>

    <vl-grid v-if="fields.length">
      <vl-column width="6" width-xs="4">
        <vl-form-message-label> {{ $t('deliverytransformation.detail.fromKey') }} </vl-form-message-label>
      </vl-column>
      <vl-column width="5" width-xs="4">
        <vl-form-message-label> {{ $t('deliverytransformation.detail.toKey') }} </vl-form-message-label>
      </vl-column>
      <vl-column width="1" width-xs="4"> </vl-column>
      <vl-column width="12" width-xs="12">
        <hr />
      </vl-column>
    </vl-grid>

    <vl-grid v-for="(field, idx) in fields" :key="field.key">
      <vl-column width="6" width-xs="4">
        <PbsSelectField
          :name="`attributeMappings[${idx}].from`"
          :placeholder="$t('deliverytransformation.detail.fromAttribute')"
          :options="data.fromAttributeOptions"
          :custom-label="entityAttributeLabel"
        />
      </vl-column>
      <vl-column width="5" width-xs="4">
        <PbsSelectField
          :name="`attributeMappings[${idx}].to`"
          :placeholder="$t('deliverytransformation.detail.toAttribute')"
          :options="data.toAttributeOptions"
          :custom-label="entityAttributeLabel"
        />
      </vl-column>
      <vl-column width="1" width-xs="4">
        <vl-action-group>
          <vl-button type="button" icon="trash" mod-icon mod-error @click="remove(idx)"></vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>

    <vl-grid v-if="!fields.length">
      <vl-column width="12" width-xs="12">
        {{ $t('deliveryTransformationAttributes.detail.empty') }}
      </vl-column>
    </vl-grid>
    <PbsErrorField name="attributeMappings" />
  </FieldArray>
</template>
<script lang="ts" setup>
import { computed, reactive, watch, onMounted } from 'vue';
import { EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { FieldArray, Field } from 'vee-validate';
import { useDeliveryEntityStore } from '@/modules/configuration/store/delivery-entity/delivery-entity.store';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsErrorField from '@/components/forms/PbsErrorField.vue';

const deliveryEntityStore = useDeliveryEntityStore();
const operationEntityStore = useOperationEntityStore();

const props = defineProps<{
  definitionId: number;
  fromEntityId: number;
  toEntityId: number;
}>();

const data = reactive({
  fromAttributeOptions: [] as EntityAttributeDetailDto[],
  toAttributeOptions: [] as EntityAttributeDetailDto[],
});

const entityAttributeLabel = (entityAttribute: EntityAttributeDetailDto) => {
  if (!entityAttribute.id) {
    return '';
  }
  return `${entityAttribute.code} (${entityAttribute.label})`;
};

const fromEntityOptions = computed(() => {
  const key = `definition-${props.definitionId}`;
  return deliveryEntityStore.items.find((x) => x.key === key)?.value ?? [];
});

const toEntityOptions = computed(() => {
  const key = `definition-${props.definitionId}`;
  return operationEntityStore.items.find((x) => x.key === key)?.value ?? [];
});

const fromEntityId = computed(() => {
  return props.fromEntityId;
});

const toEntityId = computed(() => {
  return props.toEntityId;
});

watch(fromEntityOptions, (newValue, oldValue) => {
  if (newValue !== oldValue && fromEntityId.value) {
    setFromAttributeOptions(fromEntityId.value);
  }
});

watch(toEntityOptions, (newValue, oldValue) => {
  if (newValue !== oldValue && toEntityId.value) {
    setToAttributeOptions(toEntityId.value);
  }
});

watch(fromEntityId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    setFromAttributeOptions(newValue);
  }
});

watch(toEntityId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    setToAttributeOptions(newValue);
  }
});

onMounted(() => {
  if (fromEntityId.value) {
    setFromAttributeOptions(fromEntityId.value);
  }
  if (toEntityId.value) {
    setToAttributeOptions(toEntityId.value);
  }
});

const setFromAttributeOptions = (fromEntityId: number) => {
  const fromEntity = fromEntityId && fromEntityOptions.value.find((x) => x.id === fromEntityId);
  data.fromAttributeOptions = fromEntity?.model?.attributes ?? [];
};

const setToAttributeOptions = (toEntityId: number) => {
  const toEntity = toEntityId && toEntityOptions.value.find((x) => x.id === toEntityId);
  data.toAttributeOptions = toEntity?.model?.attributes ?? [];
};
</script>

<style lang="scss">
@import '@govflanders/vl-ui-design-system-style/scss/core/_core.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';
</style>
