<template>
  <router-link v-if="link" :to="link" class="vl-link">
    <vl-icon icon="arrow-left-fat" />
    <span>{{ label }}</span>
  </router-link>
  <a v-else class="vl-link router-link-active" @click="goBack">
    <vl-icon icon="arrow-left-fat" />
    <span>{{ label }}</span>
  </a>
</template>

<script lang="ts" setup>
const props = defineProps({
  label: {
    type: String,
    required: true,
    default: 'go back',
  },
  link: {
    type: String,
    required: false,
    default: '',
  },
});

const goBack = () => {
  window.history.back();
};
</script>

<style scoped lang="scss"></style>
