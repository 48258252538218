import { createInstance, dateReviver } from '@/api';
import {
    DeliveryProcessDefinitionBasicDto,
    EnumerationDto,
    OrderDirection,
    OrganisationSearchResultDto,
    ProcessTriggerClient,
    ProcessTriggerSearchResultDto,
} from '@/api/portal-api/clients';
import { useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { i18n } from '@/i18n';

export interface ProcessTriggerListFilter {
    id?: number;
    triggerType?: EnumerationDto;
    description?: string;
    deliveryProcessDefinition?: DeliveryProcessDefinitionBasicDto;
    organisation?: OrganisationSearchResultDto;
    order?: string;
    orderDirection?: OrderDirection;
    pageNumber?: number;
    pageSize?: number;
}

export interface ProcessTriggerListStoreState {
    filter: ProcessTriggerListFilter;
    loading: boolean;
    items?: ProcessTriggerSearchResultDto[];
    totalCount?: number;
}

export const client = new ProcessTriggerClient('', createInstance(), dateReviver);

export const useProcessTriggerListStore = defineStore('process-trigger-list-store', {
    state: (): ProcessTriggerListStoreState => ({
        filter: {
            orderDirection: OrderDirection.Ascending,
            pageNumber: 1,
            pageSize: 25,
        },
        totalCount: 0,
        loading: false,
        items: [],
    }),
    getters: {
        metaData(): {
            totalRows: number;
            resultsPerPage: number;
            currentPage: number | undefined;
            serverside: boolean;
        } {
            return {
                totalRows: this.totalCount,
                resultsPerPage: this.filter.pageSize,
                currentPage: this.filter.pageNumber,
                serverside: true,
            };
        },
        getProcessTrigger(): (id: number) => ProcessTriggerSearchResultDto | undefined {
            return (id: number) => this.items?.find((item) => item.id === id);
        },
    },
    actions: {
        init(filter: ProcessTriggerListFilter) {
            this.filter = { ...this.filter, ...filter };
            this.items = [];
        },
        clearFilter() {
            this.filter = {
                orderDirection: OrderDirection.Ascending,
                pageNumber: 1,
                pageSize: 25,
            };

            useAlertStore().clearAlert();

            this.items = [];
        },
        updateItem(item: ProcessTriggerSearchResultDto) {
            const index = this.items?.findIndex((i) => i.id === item.id);
            if (index !== undefined && index !== -1) {
                this.items?.splice(index, 1, item);
            }
        },
        async getProcessTriggers() {
            this.loading = true;
            useAlertStore().clearAlert();

            const order = mapToQueryableOrder(this.filter.order);

            try {
                const response = await client.search(
                    this.filter.id,
                    this.filter.triggerType?.name,
                    this.filter.description,
                    this.filter.deliveryProcessDefinition?.id,
                    this.filter.organisation?.id,
                    order,
                    this.filter.orderDirection,
                    this.filter.pageNumber,
                    this.filter.pageSize,
                );

                this.items = response.items;
                this.filter.pageNumber = response.page;
                this.filter.pageSize = response.pageSize;
                this.totalCount = response.totalCount;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('processtrigger.list.error.title'),
                    i18n.global.t('processtrigger.list.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
        updatePage(pageNumber: number) {
            this.filter.pageNumber = pageNumber;
        },
        updateSorting(column: string, order: string) {
            this.filter.order = column;
            this.filter.orderDirection = order === 'Asc' ? OrderDirection.Ascending : OrderDirection.Descending;
        },
    },
});

function mapToQueryableOrder(order: string) {
    switch (order) {
        case 'organisation':
            return 'deliveryProcessDefinition.organisation.name';
        default:
            return order;
    }
}
