<template>
  <form>
    <vl-properties-list v-vl-spacer:bottom.small>
      <vl-properties-label>{{ $t('productspecification.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    </vl-properties-list>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="label"
        :label="t('productspecification.detail.label')"
        :placeholder="t('productspecification.detail.label')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('productspecification.detail.description')"
        :placeholder="t('productspecification.detail.description')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="dataConnection"
        :label="t('productspecification.detail.dataConnection')"
        :placeholder="$t('productspecification.detail.dataConnection')"
        :mod-loading="dataConnectionStore.loading"
        :options="dataConnections"
        :custom-label="(e) => e.label"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="schema"
        :label="t('productspecification.detail.schema')"
        :placeholder="t('productspecification.detail.schema')"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField
        name="isMetadataContinuous"
        :label="$t('productspecification.detail.isMetadataContinuous')"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import {
  DataConnectionDto,
  ProductSpecificationDetailDto,
  UpdateProductSpecificationDto,
} from '@/api/portal-api/clients';
import { useDataconnectionStore } from '@/modules/configuration/store/dataconnection/dataconnection.store';
import { useProductSpecificationStore } from '@/modules/configuration/store/product-specification/product-specification.store';
import { useForm } from 'vee-validate';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { boolean, object, string } from 'yup';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const store = useProductSpecificationStore();
const dataConnectionStore = useDataconnectionStore();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  detail: ProductSpecificationDetailDto;
}>();

const validationSchema = object({
  label: string()
    .required(t('validations.messages.required', { _field_: t('productspecification.detail.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('productspecification.detail.label'), length: 256 })),
  description: string().required(
    t('validations.messages.required', { _field_: t('productspecification.detail.description') }),
  ),
  dataConnection: object().required(
    t('validations.messages.required', { _field_: t('productspecification.detail.dataConnection') }),
  ),
  schema: string().required(t('validations.messages.required', { _field_: t('productspecification.detail.schema') })),
  isMetadataContinuous: boolean(),
});

const { resetForm, handleSubmit } = useForm({
  validationSchema,
});

const definitionId = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const dataConnections = computed((): DataConnectionDto[] => {
  return dataConnectionStore.items ?? [];
});

onMounted(async () => {
  resetForm({
    values: {
      label: props.detail.label,
      description: props.detail.description,
      dataConnection: props.detail.dataConnection,
      schema: props.detail.schema,
      isMetadataContinuous: props.detail.isMetadataContinuous,
    },
  });
  await dataConnectionStore.get();
});

const onSuccess = async (values) => {
  const updateDto: UpdateProductSpecificationDto = {
    label: values.label,
    description: values.description,
    dataConnectionId: values.dataConnection.id,
    schema: values.schema,
    isMetadataContinuous: values.isMetadataContinuous,
  };

  await store.update(definitionId.value, props.detail.id, updateDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
