import { defineStore } from 'pinia';

export enum EntityModelEditType {
    EntityModel,
    EntityAttribute,
}
export interface EntityModelEditStoreState {
    create?: {
        type?: EntityModelEditType;
    };
    edit?: {
        key?: number;
        type?: EntityModelEditType;
    };
    delete?: {
        key?: number;
        type?: EntityModelEditType;
    };
}

export const useEntityModelEditStore = defineStore('entity-model-edit-store', {
    state: (): EntityModelEditStoreState => ({
        create: null,
        edit: null,
        delete: null,
    }),
    getters: {
        isCreate(): (type: EntityModelEditType) => boolean {
            return (type: EntityModelEditType) => this.create?.type === type;
        },
        creating(): boolean {
            return this.create !== null && this.create?.type !== null;
        },
        isEdit(): (type: EntityModelEditType, id: number) => boolean {
            return (type: EntityModelEditType, id: number) => this.edit?.type === type && this.edit?.key === id;
        },
        editing(): boolean {
            return this.edit !== null && this.edit?.type !== null && this.edit?.key !== null;
        },
        isDelete(): (type: EntityModelEditType, id: number) => boolean {
            return (type: EntityModelEditType, id: number) => this.delete?.type === type && this.delete?.key === id;
        },
        deleting(): boolean {
            return this.delete !== null && this.delete?.type !== null && this.delete?.key !== null;
        },
    },
    actions: {
        openCreate(type: EntityModelEditType) {
            this.create = { type };
            this.edit = null;
            this.delete = null;
        },
        closeCreate() {
            this.create = null;
        },
        openEdit(type: EntityModelEditType, key: number) {
            this.edit = { type, key };
            this.create = null;
            this.delete = null;
        },
        closeEdit() {
            this.edit = null;
        },
        openDelete(type: EntityModelEditType, key: number) {
            this.delete = { type, key };
            this.create = null;
            this.edit = null;
        },
        closeDelete() {
            this.delete = null;
        },
    },
});
