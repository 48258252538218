import Vue, { reactive } from 'vue';

import {
    clients,
    DeliveryFilter,
    DeliveryProcessDefinition,
    DeliverySearchResult,
    DeliveryStatus,
    IDeliveryRunClient,
    Paging,
    SearchResultOfDeliveryFilterAndDeliverySearchResult,
    Sorting,
    Supplier,
} from '@/api';
import downloadBlob from '@/helpers/download.helpers';
import { stores } from '@/store';
import { ISupplierStore } from '@/store/supplier.store';
import { IDeliveryProcessDefinitionStore } from '@/store/deliveryProcessDefinition.store';
import { IDeliveryRunStore } from '@/store/deliveryRun.store';

export class DeliveryListService {
    public state: SearchResultOfDeliveryFilterAndDeliverySearchResult = reactive({
        filter: {},
        results: [],
        paging: {},
        sorting: {},
    });

    constructor(
        private deliveryClient: IDeliveryRunClient,
        private deliveryStore: () => IDeliveryRunStore,
        private definitionStore: () => IDeliveryProcessDefinitionStore,
        private supplierStore: () => ISupplierStore,
    ) {}

    get definitions(): DeliveryProcessDefinition[] {
        return this.definitionStore().definitions;
    }
    get statusses(): DeliveryStatus[] {
        return this.deliveryStore().statusses;
    }
    get filter(): DeliveryFilter {
        return this.state.filter || {};
    }
    get suppliers(): Supplier[] {
        return this.supplierStore().suppliers;
    }

    async search(filter: DeliveryFilter = {}, sorting: Sorting = {}, paging: Paging = { pageNumber: 1, pageSize: 10 }) {
        const results = await this.deliveryClient.getAll(
            filter.definition,
            filter.supplier,
            filter.fileName,
            filter.username,
            filter.startExecutionFrom,
            filter.startExecutionTo,
            filter.status,
            sorting.by,
            sorting.descending,
            paging.pageNumber,
            paging.pageSize,
        );

        Object.assign(this.state, results);
    }

    async init() {
        this.definitionStore().fetchDefinitions();
        this.deliveryStore().fetchStatusses();
        this.supplierStore().fetchSuppliers();
    }

    async download(delivery: DeliverySearchResult): Promise<void> {
        if (!delivery?.id) {
            return;
        }
        const file = await this.deliveryClient.getFile(delivery.id);

        return new Promise((resolve, reject) => {
            try {
                downloadBlob(file.data, file.fileName, () => {
                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        });
    }
}

export const DeliveryListServiceInstance = new DeliveryListService(
    clients.DeliveryRunClient,
    stores.DeliveryStore,
    stores.DeliveryProcessDefinitionStore,
    stores.SupplierStore,
);
