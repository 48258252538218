<template>
  <form>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromEntity"
        :label="t('entitylink.detail.fromEntity')"
        :placeholder="$t('entitylink.detail.fromEntity')"
        :options="fromEntityOptions"
        :custom-label="entityModelLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toEntity"
        :label="t('entitylink.detail.toEntity')"
        :placeholder="$t('entitylink.detail.toEntity')"
        :options="toEntityOptions"
        :custom-label="entityModelLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromAttribute"
        :label="t('entitylink.detail.fromAttribute')"
        :placeholder="$t('entitylink.detail.fromAttribute')"
        :options="fromAttributeOptions"
        :mod-disabled="!values.fromEntity"
        :custom-label="entityAttributeLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toAttribute"
        :label="t('entitylink.detail.toAttribute')"
        :placeholder="$t('entitylink.detail.toAttribute')"
        :options="toAttributeOptions"
        :mod-disabled="!values.toEntity"
        :custom-label="entityAttributeLabel"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { SearchEntityModelDto, DeliveryEntityLinkDetailDto, EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { useForm } from 'vee-validate';
import { object } from 'yup';
import { useI18n } from 'vue-i18n';
import { useDeliveryEntityStore } from '@/modules/configuration/store/delivery-entity/delivery-entity.store';
import { useDeliveryEntityLinkStore } from '@/modules/configuration/store/delivery-entity-link/delivery-entity-link.store';
import { useDeliveryProcessDefinitionEditStore } from '@/modules/configuration/store/delivery-process-definition/delivery-process-definition-edit.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const { t } = useI18n();

const entityStore = useDeliveryEntityStore();
const entityLinkStore = useDeliveryEntityLinkStore();
const editStore = useDeliveryProcessDefinitionEditStore();

const props = defineProps<{
  detail: DeliveryEntityLinkDetailDto;
  specificationId: number;
}>();

const validationSchema = object({
  fromEntity: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.fromEntity') })),
  toEntity: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.toEntity') })),
  fromAttribute: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.fromEntity') })),
  toAttribute: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.toEntity') })),
});

const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const fromEntityOptions = computed(() => {
  const key = `specification-${props.specificationId}`;
  return entityStore.items.find((x) => x.key === key)?.value.filter((x) => x.id !== values.toEntity?.id) ?? [];
});

const toEntityOptions = computed(() => {
  const key = `specification-${props.specificationId}`;
  return entityStore.items.find((x) => x.key === key)?.value.filter((x) => x.id !== values.fromEntity?.id) ?? [];
});

const fromAttributeOptions = computed(() => {
  return values.fromEntity?.model?.attributes ?? [];
});

const toAttributeOptions = computed(() => {
  return values.toEntity?.model?.attributes ?? [];
});

watch(
  fromAttributeOptions,
  () => {
    if (!fromAttributeOptions.value.find((x) => x.id === values?.fromAttribute?.id)) {
      setFieldValue('fromAttribute', null, false);
    }
  },
  { deep: true },
);

watch(
  toAttributeOptions,
  () => {
    if (!toAttributeOptions.value.find((x) => x.id === values?.toAttribute?.id)) {
      setFieldValue('toAttribute', null, false);
    }
  },
  { deep: true },
);

onMounted(async () => {
  resetForm({
    values: {
      fromEntity: props.detail.fromEntity,
      toEntity: props.detail.toEntity,
      fromAttribute: props.detail.attributeMappings[0]?.fromAttribute ?? null,
      toAttribute: props.detail.attributeMappings[0]?.toAttribute ?? null,
    },
  });
});

const onSuccess = async (values) => {
  await entityLinkStore.updateEntity(
    props.specificationId,
    props.detail.id,
    values.fromEntity?.id,
    values.toEntity?.id,
    props.detail.attributeMappings[0]?.linkId,
    values.fromAttribute?.id,
    values.toAttribute?.id,
  );
  editStore.closeEdit();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const entityModelLabel = (type: SearchEntityModelDto) => {
  return `${type.code} (${type.label})`;
};

const entityAttributeLabel = (type: EntityAttributeDetailDto) => {
  return `${type.code} (${type.label})`;
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
