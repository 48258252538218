import { createInstance, dateReviver } from '@/api';
import {
    EntityAttributeClient,
    EntityAttributeDetailDto,
    UpdateEntityAttributeDto,
    CreateEntityAttributeDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useEntityModelEditStore } from '../entity-model/entity-model-edit.store';

export interface EntityAttributeStoreState {
    items?: { key: number; value: EntityAttributeDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new EntityAttributeClient('', createInstance(), dateReviver);

export const useEntityAttributeStore = defineStore('entity-attribute-store', {
    state: (): EntityAttributeStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {},
    actions: {
        async getByModel(modelId: number, reloading: boolean = false) {
            this.clear(modelId);
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                const response = await client.getByModel(modelId);
                if (response?.length) {
                    this.items = [...this.items, { key: modelId, value: response }];
                }
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entityAttribute.detail.error.get.title'),
                    i18n.global.t('entityAttribute.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(dto: CreateEntityAttributeDto) {
            try {
                this.reloading = true;
                await client.create(dto);
                useAlertStore().setSuccess(
                    i18n.global.t('entityAttribute.detail.success.create.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByModel(dto.entityModelId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entityAttribute.detail.error.create.title'),
                    i18n.global.t('entityAttribute.detail.error.create.content'),
                );
            } finally {
                useEntityModelEditStore().closeCreate();
                this.reloading = false;
            }
        },
        async update(modelId: number, id: number, dto: UpdateEntityAttributeDto) {
            try {
                this.reloading = true;
                await client.update(id, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('entityAttribute.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByModel(modelId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entityAttribute.detail.error.update.title'),
                    i18n.global.t('entityAttribute.detail.error.update.content'),
                );
            } finally {
                useEntityModelEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async remove(modelId: number, id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('entityAttribute.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                await this.getByModel(modelId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('entityAttribute.detail.error.delete.title'),
                    i18n.global.t('entityAttribute.detail.error.delete.content'),
                );
            } finally {
                useEntityModelEditStore().closeDelete();
                this.reloading = false;
            }
        },
        clear(modelId: number) {
            this.items = this.items.filter((x) => x.key !== modelId);
        },
    },
});
