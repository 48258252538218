<template>
  <vl-input-group id="vl-datepicker" ref="rootRef" :class="classes">
    <vl-input-field
      ref="input"
      v-model="state.selectedDate"
      v-bind="$attrs"
      :value="state.selectedDates"
      data-input
      :mod-error="modError"
      :mod-disabled="modDisabled"
      mod-block
    />
    <vl-input-addon
      ref="addonRef"
      type="button"
      tag-name="button"
      :icon="addonIcon"
      class="vl-datepicker__input-addon"
      :tooltip="state.tooltip"
      :text="state.text"
      data-toggle
      :mod-disabled="modDisabled"
    />
  </vl-input-group>
</template>

<script lang="ts">
import Flatpickr from 'flatpickr';
import LabelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import Dutch from 'flatpickr/dist/l10n/nl.js';

export default defineComponent({
  inheritAttrs: false,
  props: {
    tagName: {
      type: String,
      default: 'div',
    },
    /**
     * Value format
     */
    format: {
      type: String,
      default: 'd-m-Y',
    },
    /**
     * Visual format
     */
    visualFormat: {
      type: String,
      default: 'd.m.Y',
    },
    /**
     * Default value
     */
    value: {
      type: [Date, String, Array],
      default: () => [],
    },
    /**
     * Minimum input date
     */
    minDate: {
      type: [String, Date],
      default: null,
    },
    /**
     * Maximum input date
     */
    maxDate: {
      type: [String, Date],
      default: null,
    },
    /**
     * Turn off datepicker
     */
    disableDate: {
      type: Boolean,
      default: false,
    },
    /**
     * Hides the day selection in calendar. Use it along with enableTime to create a time picker.
     */
    noCalendar: {
      type: Boolean,
      default: false,
    },
    /**
     * Error variant of datepicker
     */
    modError: {
      type: Boolean,
      default: false,
    },
    /**
     * Disabled variant of button
     */
    modDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * String or Date object defining default date value
     */
    defaultDate: {
      type: [String, Date],
      default: null,
    },
    /**
     * Error variant of button
     */
    defaultTime: {
      type: [String, Date],
      default: null,
    },
    /**
     * Enables time picker
     */
    enableTime: {
      type: Boolean,
      default: false,
    },
    /**
     * Set 24h time selection
     */
    timeTwentyfour: {
      type: Boolean,
      default: true,
    },
    /**
     * Minimum time value
     */
    minTime: {
      type: String,
      default: null,
    },
    /**
     * Maximum time value
     */
    maxTime: {
      type: String,
      default: null,
    },
    /**
     * visually hidden text for screenreaders
     */
    addonText: {
      type: String,
      default: null,
    },
    /**
     * String displayed in addon tooltip
     */
    addonTooltip: {
      type: String,
      default: null,
    },
    /**
     * Icon displayed in addon button
     */
    addonIcon: {
      type: String,
      default: 'calendar',
    },
    /**
     * function that expects a date string and must return a Date object
     */
    parseDate: {
      type: Function,
      default: null,
    },
    /**
     * Datepicker mode: "single", "multiple", or "range"
     */
    mode: {
      type: String,
      default: 'single',
    },
    /**
     * Mark today in the calendar
     */
    modMarkToday: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['input', 'inputChanged'],
  setup(props, context) {
    const classes = computed(() => {
      return ['vl-datepicker'];
    });

    const addonRef = ref<HTMLElement | null>(null);

    const rootRef = ref<HTMLElement | null>(null);

    let settings: object = {
      allowInput: true,
      dateFormat: props.format,
      altFormat: props.visualFormat,
      altInput: true,
      minDate: props.minDate,
      maxDate: props.maxDate,
      defaultDate: props.defaultDate,
      locale: Dutch.nl,
      clickOpens: false,
      wrap: true,
      disableDate: props.disableDate,
      enableTime: props.enableTime,
      noCalendar: props.disableDate,
      time_24hr: props.timeTwentyfour,
      minTime: props.minTime,
      maxTime: props.maxTime,
      defaultTime: props.defaultTime,
      parseDate: props.parseDate,
      mode: props.mode,
      onChange: (dates: any) => {
        state.selectedDates = dates;
      },
      /**
       * Workaround for flatpickr range modus losing
       * its value when a second date has not been chosen
       */
      onClose: (dates: string[], dateStr: string, instance: any) => {
        if (instance.config && instance.config.mode && instance.config.mode === 'range') {
          if (dates.length === 1) {
            instance.setDate([state.selectedDates[0], dates[0]], true);
          }
        }
      },
      plugins: [new (LabelPlugin as any)()],
    };

    // sanitize options if undefined or null (then defaults get used)
    settings = omitBy(settings, isNil);

    const state: any = reactive({
      flatpickrInstance: null,
      selectedDate: props.defaultDate,
      selectedDates: [],
      text: props.addonText ? props.addonText : 'kies datum',
      tooltip: props.addonTooltip ? props.addonTooltip : 'kies datum',
      settings: settings,
    });

    const tryParseDate = (value: string | Date | unknown) => {
      let date;
      try {
        date = state.flatpickrInstance.parseDate(value, props.format);
      } catch (e) {
        // Do nothing
      }
      return date;
    };

    const tryParseDates = (values: Array<unknown>) => {
      let dates = [];
      values.forEach((value) => {
        const date = tryParseDate(value);
        if (date) {
          dates.push(date);
        }
      });
      return dates;
    };

    const trySetDate = (value: string | Date | Array<unknown>, triggerChange = false) => {
      if (value) {
        if (Array.isArray(value)) {
          const dates = tryParseDates(value);
          state.selectedDates = dates;
          state.flatpickrInstance.setDate(dates, triggerChange);
        } else {
          const date = tryParseDate(value);
          if (date) {
            state.selectedDates = [date];
            state.flatpickrInstance.setDate(date, triggerChange);
          }
        }
      } else {
        state.selectedDates = [];
        state.flatpickrInstance.clear();
      }
    };

    onMounted(() => {
      const positionElement = { positionElement: (addonRef.value as any).$el };
      Object.assign(state.settings, positionElement);

      state.flatpickrInstance = new (Flatpickr as any)((rootRef.value as any).$el, state.settings);

      if (state.flatpickrInstance) {
        if (props.modMarkToday && state.flatpickrInstance.calendarContainer) {
          state.flatpickrInstance.calendarContainer.classList.add('mark-today');
        }
        state.selectedDates = state.flatpickrInstance.selectedDates;
        if (state.flatpickrInstance.altInput) {
          state.flatpickrInstance.altInput.classList.add('vl-datepicker__input-field');
          if (typeof state.flatpickrInstance.altInput.addEventListener === 'function') {
            state.flatpickrInstance.altInput.addEventListener(
              'input',
              debounce((event) => {
                context.emit('inputChanged', event);
                // Dispatch Input event when a full date is entered through the altInput
                if (state.flatpickrInstance.altInput.value.length === 10) {
                  trySetDate(state.flatpickrInstance.altInput.value, true);
                }
              }, 200),
            );
          }
        }
        // when default date is set through value instead of defaultDate
        if (state.value?.length) {
          trySetDate(state.value, false);
        }
      }
    });

    watch(
      () => props.value,
      (newVal, oldValue) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldValue)) {
          trySetDate(newVal, false);
        }
      },
    );

    watch(
      () => props.modError,
      () => {
        state.flatpickrInstance.altInput.classList.toggle('vl-input-field--error');
      },
    );

    watch(
      () => props.modDisabled,
      (newVal) => {
        // reflect 2-way binding state change to flatpicker input-field
        if (newVal === true) {
          state.flatpickrInstance.altInput.setAttribute('disabled', newVal);
        } else {
          state.flatpickrInstance.altInput.removeAttribute('disabled');
        }
        state.flatpickrInstance.altInput.classList.toggle('vl-input-field--disabled');
      },
    );

    watch(
      () => state.selectedDate,
      (newVal, oldVal) => {
        /**
         * Check if the date has actually changed before emitting
         * a change event. When no date has been chosen, pass
         * an empty string instead of an undefined var for the
         * date comparision to work as expected.
         */
        newVal = typeof newVal !== 'undefined' ? newVal : '';
        oldVal = typeof oldVal !== 'undefined' ? oldVal : '';

        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          context.emit('input', state.selectedDates);
        } else if (newVal === '' && oldVal === '') {
          // Also emit when there are no values, otherwise the model will not update
          context.emit('input', []);
        }
      },
    );

    watch(
      () => props.minDate,
      (value) => {
        state.flatpickrInstance.set('minDate', value);
      },
    );

    watch(
      () => props.maxDate,
      (value) => {
        state.flatpickrInstance.set('maxDate', value);
      },
    );

    onBeforeUnmount(() => {
      if (state.flatpickrInstance) {
        state.flatpickrInstance.destroy();
      }

      if (state.flatpickrInstance && state.flatpickrInstance.altInput) {
        if (typeof state.flatpickrInstance.altInput.removeEventListener === 'function') {
          state.flatpickrInstance.altInput.removeEventListener(
            'input',
            debounce((event) => {
              context.emit('inputChanged', event);
            }, 200),
          );
        }
      }
    });

    return {
      state,
      addonRef,
      rootRef,
      classes,
    };
  },
});
</script>
