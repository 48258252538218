<template>
  <form>
    <vl-properties-list>
      <vl-properties-label>{{ $t('processtrigger.detail.id') }}</vl-properties-label>
      <vl-properties-data>{{ detail.id }}</vl-properties-data>
    </vl-properties-list>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="triggerType"
        :label="t('processtrigger.detail.triggerType')"
        :placeholder="$t('processtrigger.detail.triggerType')"
        :options="triggerTypes"
        :custom-label="enumerationLabel"
      />
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsTextAreaField
        name="description"
        :label="t('processtrigger.detail.description')"
        :placeholder="t('processtrigger.detail.description')"
      />
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="deliveryProcessDefinition"
        :label="t('productprocessdefinition.detail.deliveryprocessdefinition')"
        :placeholder="$t('productprocessdefinition.detail.deliveryprocessdefinition')"
        :options="deliveryProcessDefinitionOptions"
        :custom-label="(e) => e.label"
        @search-change="onSearchDeliveryProcessDefinitions"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger" v-vl-spacer:bottom.small>
      <PbsInputField
        name="schedule"
        :label="t('processtrigger.detail.schedule')"
        :placeholder="t('processtrigger.detail.schedule')"
      />
    </vl-form-column>

    <vl-form-column v-if="isTimeTrigger" v-vl-spacer:bottom.small>
      <PbsInputField name="job" :label="t('processtrigger.detail.job')" :placeholder="t('processtrigger.detail.job')" />
    </vl-form-column>

    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="isEnabled" :label="$t('processtrigger.detail.isEnabled')" />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import {
  ProcessTriggerDetailDto,
  EnumerationDto,
  DeliveryProcessDefinitionSearchResultDto,
} from '@/api/portal-api/clients';
import { useProcessTriggerDetailStore } from '@/modules/configuration/store/process-trigger/process-trigger-detail.store';
import { useForm } from 'vee-validate';
import { Ref, computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ENUM_TriggerTypes } from '@/modules/configuration/common';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import { useDeliveryProcessDefinitionListStore } from '../../store/delivery-process-definition/delivery-process-definition-list.store';
import { useProcessTriggerValidations } from './process-trigger-validations';

const store = useProcessTriggerDetailStore();
const deliveryProcessDefinitionStore = useDeliveryProcessDefinitionListStore();
const { t } = useI18n();

const deliveryProcessDefinitionOptions: Ref<DeliveryProcessDefinitionSearchResultDto[]> = ref([]);

const detail = computed((): ProcessTriggerDetailDto => {
  return store.detail;
});

const { validationSchema, triggerTypeRef } = useProcessTriggerValidations(t, detail.value);

const { resetForm, handleSubmit, values } = useForm({
  validationSchema,
});

const triggerType = computed(() => {
  return values.triggerType;
});

watch(triggerType, (newValue, oldValue) => {
  triggerTypeRef.value = newValue;
});

onMounted(async () => {
  deliveryProcessDefinitionOptions.value = await deliveryProcessDefinitionStore.getAll();

  resetForm({
    values: {
      triggerType: detail.value.triggerType,
      description: detail.value.description,
      schedule: detail.value.schedule,
      job: detail.value.job,
      deliveryProcessDefinition: detail.value.deliveryProcessDefinition,
      isEnabled: detail.value.isEnabled,
    },
  });
});

const triggerTypes = computed((): EnumerationDto[] => {
  return ENUM_TriggerTypes;
});

const isTimeTrigger = computed(() => {
  return values.triggerType?.id === 3;
});

const onSuccess = async (values) => {
  await store.update(detail.value.id, {
    triggerType: values.triggerType.name,
    description: values.description,
    deliveryProcessDefinitionId: values.deliveryProcessDefinition?.id,
    schedule: isTimeTrigger.value ? values.schedule : '',
    job: isTimeTrigger.value ? values.job : null,
    isEnabled: values.isEnabled,
  });
};

const onInvalidSubmit = ({ values, errors, results }) => {
  console.log(values); // current form values
  console.log(errors); // a map of field names and their first error message
  console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchDeliveryProcessDefinitions = async (query: string) => {
  deliveryProcessDefinitionOptions.value = deliveryProcessDefinitionStore.search(query);
};

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
, DeliveryProcessDefinitionSearchResultDtoRef, , ref
