<template>
  <template v-if="!loading">
    <vl-grid v-vl-spacer:bottom.small>
      <vl-column width="8" width-xs="12">
        <vl-title tag-name="h2"> {{ $t('entitylink.detail.title') }} </vl-title>
      </vl-column>
      <vl-column width="4" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button
            v-if="canAddEntityLink"
            icon="plus"
            :mod-disabled="isAddDisabled"
            mod-icon-before
            @click="editStore.openCreate(ProductProcessDefinitionEditType.ProductEntityLink)"
            >{{ $t('general.add') }}</vl-button
          >
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid :class="{ edit: editStore.isCreate(ProductProcessDefinitionEditType.ProductEntityLink) }">
      <vl-column>
        <ProductEntityLinkCreate
          v-if="editStore.isCreate(ProductProcessDefinitionEditType.ProductEntityLink)"
          :specification-id="id"
        />
      </vl-column>
    </vl-grid>
    <vl-grid v-vl-spacer:bottom.medium>
      <vl-column width="12" width-xs="12">
        <vl-accordion-list v-if="items && items.length">
          <vl-accordion-list-item v-for="(link, i) in items" :id="i" :key="i">
            <vl-accordion :id="i.toString()" :title="getLabel(link)">
              <vl-grid
                :class="{
                  edit: editStore.isEdit(ProductProcessDefinitionEditType.ProductEntityLink, link.id),
                }"
              >
                <vl-column width="8">
                  <ProductEntityLinkRead
                    v-if="!editStore.isEdit(ProductProcessDefinitionEditType.ProductEntityLink, link.id)"
                    :detail="link"
                  />
                  <ProductEntityLinkEdit v-else ref="editForm" :specification-id="id" :detail="link" />
                </vl-column>
                <vl-column width="4">
                  <vl-action-group
                    v-if="
                      items &&
                      items.length &&
                      !editStore.isEdit(ProductProcessDefinitionEditType.ProductEntityLink, link.id)
                    "
                    mod-align-right
                    mod-space
                    between
                  >
                    <vl-button
                      icon="pencil"
                      mod-icon-before
                      mod-secondary
                      @click="editStore.openEdit(ProductProcessDefinitionEditType.ProductEntityLink, link.id)"
                      >{{ $t('general.edit') }}</vl-button
                    >
                    <vl-button
                      icon="trash"
                      mod-icon-before
                      mod-error
                      @click="editStore.openDelete(ProductProcessDefinitionEditType.ProductEntityLink, link.id)"
                      >{{ $t('general.delete') }}</vl-button
                    >
                  </vl-action-group>
                  <vl-action-group v-else mod-align-right mod-space between>
                    <vl-button icon="save" x mod-icon-before @click="save">{{ $t('general.save') }}</vl-button>
                    <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeEdit">
                      {{ $t('general.cancel') }}
                    </vl-button>
                  </vl-action-group>
                </vl-column>
                <template v-if="!editStore.isEdit(ProductProcessDefinitionEditType.ProductEntityLink, link.id)">
                  <vl-column width="10">
                    <vl-title tag-name="h4"> {{ $t('entitylink.detail.attributes') }} </vl-title>
                  </vl-column>
                  <ProductEntityLinkAttributes :detail="link" :specification-id="id" />
                </template>
              </vl-grid>
            </vl-accordion>
          </vl-accordion-list-item>
        </vl-accordion-list>
        <template v-else>
          <vl-annotation>{{ $t('entitylink.detail.empty') }}</vl-annotation>
        </template>
      </vl-column>
    </vl-grid>
  </template>
  <vl-modal
    :id="deleteKey.toString()"
    :title="$t('entitylink.delete.title')"
    :open="editStore.isDelete(ProductProcessDefinitionEditType.ProductEntityLink, deleteKey)"
  >
    <vl-action-group>
      <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
        {{ $t('general.delete') }}
      </vl-button>
      <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </vl-modal>
</template>
<script lang="ts" setup>
import { ProductEntityLinkDetailDto } from '@/api/portal-api/clients';
import { useProductEntityLinkStore } from '@/modules/configuration/store/product-entity-link/product-entity-link.store';
import { VlButton, VlActionGroup } from '@govflanders/vl-ui-design-system-vue3';
import { computed, onMounted, ref, Ref } from 'vue';
import ProductEntityLinkRead from './ProductEntityLinkRead.vue';
import ProductEntityLinkAttributes from './ProductEntityLinkAttributes.vue';
import {
  ProductProcessDefinitionEditType,
  useProductProcessDefinitionEditStore,
} from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useProductEntityStore } from '@/modules/configuration/store/product-entity/product-entity.store';
import ProductEntityLinkCreate from './ProductEntityLinkCreate.vue';
import ProductEntityLinkEdit from './ProductEntityLinkEdit.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const entityLinkStore = useProductEntityLinkStore();
const entityStore = useProductEntityStore();
const editStore = useProductProcessDefinitionEditStore();
const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const editForm: Ref<typeof ProductEntityLinkEdit | (typeof ProductEntityLinkEdit)[]> = ref(null);

const loading = computed((): boolean => {
  return entityLinkStore.loading;
});

const items = computed((): ProductEntityLinkDetailDto[] => {
  return entityLinkStore.items?.find((x) => x.key === props.id)?.value ?? [];
});

const isAddDisabled = computed(() => {
  return (
    editStore.isEdit(ProductProcessDefinitionEditType.ProductEntityLink, props.id) ||
    editStore.isCreate(ProductProcessDefinitionEditType.ProductEntity)
  );
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

const canAddEntityLink = computed(() => {
  const key = `specification-${props.id}`;
  return entityStore.items.find((x) => x.key === key)?.value?.length >= 2;
});

onMounted(async () => {
  if (props.id) {
    await entityLinkStore.getBySpecification(props.id);
  }
});

const save = () => {
  if (Array.isArray(editForm?.value)) {
    editForm.value[0].onSubmit();
  } else {
    editForm.value.onSubmit();
  }
};

const remove = async (id: number) => {
  await entityLinkStore.deleteEntity(props.id, id);
  editStore.closeDelete();
};

const getLabel = (item: ProductEntityLinkDetailDto): string => {
  const unknown = '(?)';
  const from = `${item.fromEntity?.code ?? unknown}`;
  const to = `${item.toEntity?.code ?? unknown}`;
  return t('entitylink.detail.linkTo', { from, to });
};
</script>
<style lang="scss" scoped>
:deep(.vl-accordion__panel) {
  padding-right: 0;
}
.vl-grid {
  flex-grow: 1;
}
</style>
