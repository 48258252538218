import { DeliveryEntityDetailDto, OperationEntityDetailDto, TransformationTypeDto } from '@/api/portal-api/clients';
import { ENUM_TransformationTypes } from '@/modules/configuration/common';

const attributeMappingTypes = ENUM_TransformationTypes.filter((type) => [2, 4, 5, 6].includes(type.id));

export const isAttributeMappingSupported = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && attributeMappingTypes.some((type) => type.id === transformationType.id);
};

export const isEntityMappingType = (transformationType?: TransformationTypeDto): boolean => {
    return transformationType && transformationType.id === 1;
};

export function hasSameEntityModel(fromEntity: DeliveryEntityDetailDto, toEntity: OperationEntityDetailDto) {
    return fromEntity?.model?.id === toEntity?.model?.id;
}
