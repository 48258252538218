<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#entitymodel" />
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('entitymodel.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="code"
            :label="t('entitymodel.detail.code')"
            :placeholder="t('entitymodel.detail.code')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsInputField
            name="label"
            :label="t('entitymodel.detail.label')"
            :placeholder="t('entitymodel.detail.label')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsTextAreaField
            name="description"
            :label="t('entitymodel.detail.description')"
            :placeholder="t('entitymodel.detail.description')"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="contextType"
            :label="t('entitymodel.detail.contextType')"
            :placeholder="$t('entitymodel.detail.contextType')"
            :options="contextTypes"
            :custom-label="enumerationLabel"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="entityType"
            :label="t('entitymodel.detail.entityType')"
            :placeholder="$t('entitymodel.detail.entityType')"
            :options="entityTypes"
            :custom-label="enumerationLabel"
          />
        </vl-form-column>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField name="hasHistory" :label="$t('entitymodel.detail.hasHistory')" />
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="loading" mod-icon-before type="submit"
            >{{ $t('general.save') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string, boolean } from 'yup';
import { useEntityModelDetailStore } from '../../store/entity-model/entity-model-detail.store';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { EnumerationDto } from '@/api/portal-api/clients';
import { ENUM_ContextTypes, ENUM_EntityTypes } from '@/modules/configuration/common';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';

const { t } = useI18n();
const store = useEntityModelDetailStore();
const router = useRouter();

const validationSchema = object({
  code: string()
    .required(t('validations.messages.required', { _field_: t('entitymodel.create.code') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('entitymodel.create.code'), length: 256 })),
  label: string()
    .required(t('validations.messages.required', { _field_: t('entitymodel.create.label') }))
    .max(256, t('validations.messages.maxChar', { _field_: t('entitymodel.create.label'), length: 256 })),
  description: string().required(t('validations.messages.required', { _field_: t('entitymodel.create.description') })),
  contextType: object().required(t('validations.messages.required', { _field_: t('entitymodel.create.contextType') })),
  entityType: object().required(t('validations.messages.required', { _field_: t('entitymodel.create.entityType') })),
  hasHistory: boolean(),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const loading = computed((): boolean => {
  return store.loading;
});

const contextTypes = computed((): EnumerationDto[] => {
  return ENUM_ContextTypes;
});

const entityTypes = computed((): EnumerationDto[] => {
  return ENUM_EntityTypes;
});

const onSuccess = async (values) => {
  const id = await store.create({
    code: values.code,
    label: values.label,
    description: values.description,
    contextType: values.contextType.name,
    entityType: values.entityType.name,
    hasHistory: values.hasHistory,
  });

  if (id) {
    router.push({
      name: 'entitymodel.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
