<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('deliverytransformation.detail.id') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.id }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliverytransformation.detail.transformationType') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.transformationType.name }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliverytransformation.detail.fromEntity') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.fromEntity ? props.detail.fromEntity.label : '-' }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliverytransformation.detail.toEntity') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.toEntity ? props.detail.toEntity.label : '-' }}
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliverytransformation.detail.parameter') }}</vl-properties-label>
    <vl-properties-data>
      {{ props.detail.parameter }}
    </vl-properties-data>
  </vl-properties-list>
  <DeliveryTransformationAttributesRead v-if="isAttributeMappingType" :detail="detail" />
</template>

<script lang="ts" setup>
import { DeliveryTransformationDetailDto } from '@/api/portal-api/clients';
import DeliveryTransformationAttributesRead from '../delivery-transformation/DeliveryTransformationAttributesRead.vue';
import { computed } from 'vue';
import { isAttributeMappingSupported } from './delivery-transformation-utils';

const props = defineProps<{
  detail: DeliveryTransformationDetailDto;
}>();

const isAttributeMappingType = computed(() => {
  return isAttributeMappingSupported(props.detail.transformationType);
});
</script>
