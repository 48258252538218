import { defineStore } from 'pinia';

export enum DeliveryProcessDefinitionEditType {
    DeliveryEntity,
    DeliveryEntityLink,
    DeliveryEntityLinkAttribute,
    DeliverySpecification,
    DeliveryTransformation,
    DeliveryTransformationAttribute,
    OperationEntity,
    OperationSpecification,
    DeliveryProcessDefinition,
}

export interface DeliveryProcessDefinitionEditStoreState {
    create?: {
        type?: DeliveryProcessDefinitionEditType;
    };
    edit?: {
        key?: number;
        type?: DeliveryProcessDefinitionEditType;
    };
    delete?: {
        key?: number;
        type?: DeliveryProcessDefinitionEditType;
    };
}

export const useDeliveryProcessDefinitionEditStore = defineStore('delivery-process-definition-edit-store', {
    state: (): DeliveryProcessDefinitionEditStoreState => ({
        create: null,
        edit: null,
        delete: null,
    }),
    getters: {
        isCreate(): (type: DeliveryProcessDefinitionEditType) => boolean {
            return (type: DeliveryProcessDefinitionEditType) => this.create?.type === type;
        },
        creating(): boolean {
            return this.create !== null && this.create?.type !== null;
        },
        isEdit(): (type: DeliveryProcessDefinitionEditType, id?: number) => boolean {
            return (type: DeliveryProcessDefinitionEditType, id: number) => {
                if (id !== null) {
                    return this.edit?.type === type && this.edit?.key === id;
                } else {
                    return this.edit?.type === type;
                }
            };
        },
        editing(): boolean {
            return this.edit !== null && this.edit?.type !== null && this.edit?.key !== null;
        },
        isDelete(): (type: DeliveryProcessDefinitionEditType, id: number) => boolean {
            return (type: DeliveryProcessDefinitionEditType, id: number) =>
                this.delete?.type === type && this.delete?.key === id;
        },
        deleting(): boolean {
            return this.delete !== null && this.delete?.type !== null && this.delete?.key !== null;
        },
    },
    actions: {
        openCreate(type: DeliveryProcessDefinitionEditType) {
            this.create = { type };
            this.edit = null;
            this.delete = null;
        },
        closeCreate() {
            this.create = null;
        },
        openEdit(type: DeliveryProcessDefinitionEditType, key: number) {
            this.edit = { type, key };
            this.create = null;
            this.delete = null;
        },
        closeEdit() {
            this.edit = null;
        },
        openDelete(type: DeliveryProcessDefinitionEditType, key: number) {
            this.delete = { type, key };
            this.create = null;
            this.edit = null;
        },
        closeDelete() {
            this.delete = null;
        },
    },
});
