<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('deliveryentity.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.code') }}</vl-properties-label>
    <vl-properties-data>{{ detail.code }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.format') }}</vl-properties-label>
    <vl-properties-data>{{ detail.format.code }} ({{ detail.format.name }})</vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.model') }}</vl-properties-label>
    <vl-properties-data>
      <router-link :to="'/configuration/entitymodel/' + detail.model.id" target="_blank">
        {{ detail.model.label }}
      </router-link>
    </vl-properties-data>
    <vl-properties-label>{{ $t('deliveryentity.detail.crs') }}</vl-properties-label>
    <vl-properties-data>{{ detail.crs.name }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { DeliveryEntityDetailDto } from '@/api/portal-api/clients';

const props = defineProps<{
  detail: DeliveryEntityDetailDto;
}>();

const detail = computed((): DeliveryEntityDetailDto => {
  return props.detail;
});
</script>
