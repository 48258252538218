import { createInstance, dateReviver } from '@/api';
import {
    SourceSpecificationClient,
    SourceSpecificationCreateDto,
    SourceSpecificationDetailDto,
    SourceSpecificationUpdateDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, useAlertStore, VerticalPosition } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from '../product-process-definition/product-process-definition-edit.store';

export interface SourceSpecificationStoreState {
    items?: { key: number; value: SourceSpecificationDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new SourceSpecificationClient('', createInstance(), dateReviver);

export const useSourceSpecificationStore = defineStore('source-specification-store', {
    state: (): SourceSpecificationStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {},
    actions: {
        async getByDefinition(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);

            try {
                const response = await client.getByDefinition(id);
                if (response.length) {
                    this.items = [{ key: id, value: response }];
                } else {
                    this.items = [];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourcespecification.detail.get.error.title'),
                    i18n.global.t('sourcespecification.detail.get.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(definitionId: number, dto: SourceSpecificationCreateDto) {
            this.reloading = true;

            try {
                const response = await client.create(dto);

                await this.getByDefinition(definitionId, true);
                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourcespecification.detail.create.error.title'),
                    i18n.global.t('sourcespecification.detail.create.error.content'),
                );
                return null;
            } finally {
                useProductProcessDefinitionEditStore().closeCreate();
                this.reloading = false;
            }
        },
        async update(definitionId: number, id: number, dto: SourceSpecificationUpdateDto) {
            this.reloading = true;

            try {
                await client.update(id, dto);

                useAlertStore().setSuccess(
                    i18n.global.t('sourcespecification.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByDefinition(definitionId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourcespecification.detail.error.update.title'),
                    i18n.global.t('sourcespecification.detail.error.update.content'),
                );
            } finally {
                useProductProcessDefinitionEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async remove(definitionId: number, id: number) {
            this.reloading = true;

            try {
                await client.delete(id);

                useAlertStore().setSuccess(
                    i18n.global.t('sourcespecification.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByDefinition(definitionId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourcespecification.detail.error.delete.title'),
                    i18n.global.t('sourcespecification.detail.error.delete.content'),
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(id: number) {
            this.items = this.items.filter((x) => x.key !== id);
        },
    },
});
