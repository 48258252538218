<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#processtrigger" />
    <vl-grid>
      <vl-column width="12" width-xs="12">
        <vl-title tag-name="h1"> {{ $t('processtrigger.create.title') }} </vl-title>
      </vl-column>
    </vl-grid>
    <form @submit="onSubmit">
      <vl-grid>
        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="triggerType"
            :label="t('processtrigger.detail.triggerType')"
            :placeholder="$t('processtrigger.detail.triggerType')"
            :options="triggerTypes"
            :custom-label="enumerationLabel"
          />
        </vl-form-column>

        <vl-form-column v-vl-spacer:bottom.small>
          <PbsTextAreaField
            name="description"
            :label="t('processtrigger.detail.description')"
            :placeholder="t('processtrigger.detail.description')"
          />
        </vl-form-column>

        <vl-form-column v-vl-spacer:bottom.small>
          <PbsSelectField
            name="deliveryProcessDefinition"
            :label="t('productprocessdefinition.detail.deliveryprocessdefinition')"
            :placeholder="$t('productprocessdefinition.detail.deliveryprocessdefinition')"
            :options="deliveryProcessDefinitionOptions"
            :custom-label="(e) => e.label"
            @search-change="onSearchDeliveryProcessDefinitions"
          />
        </vl-form-column>

        <vl-form-column v-if="isTimeTrigger" v-vl-spacer:bottom.small>
          <PbsInputField
            name="schedule"
            :label="t('processtrigger.detail.schedule')"
            :placeholder="t('processtrigger.detail.schedule')"
          />
        </vl-form-column>

        <vl-form-column v-if="isTimeTrigger" v-vl-spacer:bottom.small>
          <PbsInputField
            name="job"
            :label="t('processtrigger.detail.job')"
            :placeholder="t('processtrigger.detail.job')"
          />
        </vl-form-column>

        <vl-form-column v-vl-spacer:bottom.small>
          <PbsRadioButtonField name="isEnabled" :label="$t('processtrigger.detail.isEnabled')" />
        </vl-form-column>
        <vl-action-group v-vl-spacer:bottom.small>
          <vl-button icon="save" :mod-loading="loading" mod-icon-before type="submit"
            >{{ $t('general.save') }}
          </vl-button>
        </vl-action-group>
      </vl-grid>
    </form>
  </vl-layout>
</template>
<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useProcessTriggerDetailStore } from '../../store/process-trigger/process-trigger-detail.store';
import { useRouter } from 'vue-router';
import { Ref, computed, onMounted, ref, watch } from 'vue';
import { EnumerationDto, DeliveryProcessDefinitionSearchResultDto } from '@/api/portal-api/clients';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsTextAreaField from '@/components/forms/PbsTextAreaField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';
import { useDeliveryProcessDefinitionListStore } from '../../store/delivery-process-definition/delivery-process-definition-list.store';
import { useProcessTriggerValidations } from './process-trigger-validations';
import { ENUM_TriggerTypes } from '../../common';

const { t } = useI18n();
const store = useProcessTriggerDetailStore();
const deliveryProcessDefinitionStore = useDeliveryProcessDefinitionListStore();
const router = useRouter();

const deliveryProcessDefinitionOptions: Ref<DeliveryProcessDefinitionSearchResultDto[]> = ref([]);

const { validationSchema, triggerTypeRef } = useProcessTriggerValidations(t);

const { handleSubmit, values } = useForm({
  validationSchema,
});

const triggerType = computed(() => {
  return values.triggerType;
});

const triggerTypes = computed((): EnumerationDto[] => {
  return ENUM_TriggerTypes;
});

const isTimeTrigger = computed(() => {
  return values.triggerType?.id === 3;
});

watch(triggerType, (newValue, oldValue) => {
  triggerTypeRef.value = newValue;
});

const loading = computed((): boolean => {
  return store.loading;
});

onMounted(async () => {
  deliveryProcessDefinitionOptions.value = await deliveryProcessDefinitionStore.getAll();
});

const onSuccess = async (values) => {
  const id = await store.create({
    triggerType: values.triggerType.name,
    description: values.description,
    deliveryProcessDefinitionId: values.deliveryProcessDefinition?.id,
    schedule: isTimeTrigger.value ? values.schedule : '',
    job: isTimeTrigger.value ? values.job : null,
    isEnabled: values.isEnabled,
  });

  if (id) {
    router.push({
      name: 'processtrigger.detail',
      params: {
        id,
      },
    });
  }
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

const onSearchDeliveryProcessDefinitions = async (query: string) => {
  deliveryProcessDefinitionOptions.value = deliveryProcessDefinitionStore.search(query);
};

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

defineExpose({ onSubmit });
</script>
