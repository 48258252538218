<template>
  <vl-layout>
    <PbsGoBackLink :label="$t('general.backToResults')" link="/configuration#organisation" />
    <template v-if="!loading">
      <vl-grid>
        <vl-column width="8" width-xs="12">
          <vl-title tag-name="h1"> {{ $t('organisations.detail.title') }} </vl-title>
        </vl-column>
        <vl-column width="4" width-xs="12">
          <vl-action-group
            v-if="detail && !editStore.isEdit(OrganisationEditType.Organisation, detail.id)"
            mod-align-right
            mod-space
            between
          >
            <vl-button
              icon="pencil"
              mod-icon-before
              mod-secondary
              :mod-disabled="editStore.editing"
              @click="editStore.openEdit(OrganisationEditType.Organisation, detail.id)"
            >
              {{ $t('general.edit') }}
            </vl-button>
            <vl-button
              icon="trash"
              mod-icon-before
              mod-error
              @click="editStore.openDelete(OrganisationEditType.Organisation, id)"
              >{{ $t('general.delete') }}</vl-button
            >
          </vl-action-group>
          <vl-action-group v-else mod-align-right mod-space between>
            <vl-button icon="save" :mod-loading="reloading" mod-icon-before @click="save">{{
              $t('general.save')
            }}</vl-button>
            <vl-button
              icon="cross"
              :mod-loading="loading"
              mod-icon-before
              mod-secondary
              mod-error
              @click="editStore.closeEdit"
            >
              {{ $t('general.cancel') }}
            </vl-button>
          </vl-action-group>
        </vl-column>
      </vl-grid>
      <vl-grid
        v-if="detail"
        v-vl-spacer:bottom.medium
        :class="{ edit: editStore.isEdit(OrganisationEditType.Organisation, detail.id) }"
      >
        <vl-column>
          <OrganisationEdit v-if="editStore.isEdit(OrganisationEditType.Organisation, detail.id)" ref="editForm" />
          <OrganisationRead v-else :detail="detail" />
        </vl-column>
      </vl-grid>
    </template>
    <template v-if="loading">
      <vl-region>
        <div v-vl-align:center>
          <vl-loader :message="$t('organisations.detail.loading')" />
        </div>
      </vl-region>
    </template>
    <vl-modal
      :id="deleteKey.toString()"
      :title="$t('organisations.detail.delete.title')"
      :open="editStore.isDelete(OrganisationEditType.Organisation, deleteKey)"
    >
      <vl-action-group>
        <vl-button icon="trash" mod-icon-before mod-error @click="remove(deleteKey)">
          {{ $t('general.delete') }}
        </vl-button>
        <vl-button icon="cross" mod-icon-before mod-secondary mod-error @click="editStore.closeDelete">
          {{ $t('general.cancel') }}
        </vl-button>
      </vl-action-group>
    </vl-modal>
  </vl-layout>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteLeave, useRouter } from 'vue-router';
import { useOrganisationDetailStore } from '../../store/organisation/organisation-detail.store';
import { useOrganisationEditStore, OrganisationEditType } from '../../store/organisation/organisation-edit.store';
import { OrganisationDetailDto } from '@/api/portal-api/clients';
import OrganisationRead from './OrganisationRead.vue';
import OrganisationEdit from './OrganisationEdit.vue';
import PbsGoBackLink from '@/components/PbsGoBackLink.vue';

const store = useOrganisationDetailStore();
const editStore = useOrganisationEditStore();

const route = useRoute();
const router = useRouter();

const editForm = ref();

const detail = computed((): OrganisationDetailDto => {
  return store.detail;
});

const loading = computed((): boolean => {
  return store.loading;
});

const reloading = computed((): boolean => {
  return store.reloading;
});

const id = computed((): number => {
  return parseInt(route.params.id as string, 10);
});

const deleteKey = computed((): number => {
  return editStore.delete?.key ?? 0;
});

onMounted(async () => {
  await store.get(id.value);
});

const save = () => {
  editForm.value.onSubmit();
};

const remove = async (id: number) => {
  const isSuccess = await store.delete(id);
  editStore.closeDelete();
  if (isSuccess) {
    router.push({ name: 'configuration', hash: '#organisation' });
  }
};

onBeforeRouteLeave((to, from, next) => {
  const id = parseInt(from.params.id as string, 10);
  if (id && store) {
    store.clear(id);
  }
  next();
});
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
</style>
