import { defineStore } from 'pinia';

export enum OrganisationEditType {
    Organisation,
}
export interface OrganisationEditStoreState {
    create?: {
        type?: OrganisationEditType;
    };
    edit?: {
        key?: number;
        type?: OrganisationEditType;
    };
    delete?: {
        key?: number;
        type?: OrganisationEditType;
    };
}

export const useOrganisationEditStore = defineStore('organisation-edit-store', {
    state: (): OrganisationEditStoreState => ({
        create: null,
        edit: null,
        delete: null,
    }),
    getters: {
        isCreate(): (type: OrganisationEditType) => boolean {
            return (type: OrganisationEditType) => this.create?.type === type;
        },
        creating(): boolean {
            return this.create !== null && this.create?.type !== null;
        },
        isEdit(): (type: OrganisationEditType, id: number) => boolean {
            return (type: OrganisationEditType, id: number) => this.edit?.type === type && this.edit?.key === id;
        },
        editing(): boolean {
            return this.edit !== null && this.edit?.type !== null && this.edit?.key !== null;
        },
        isDelete(): (type: OrganisationEditType, id: number) => boolean {
            return (type: OrganisationEditType, id: number) => this.delete?.type === type && this.delete?.key === id;
        },
        deleting(): boolean {
            return this.delete !== null && this.delete?.type !== null && this.delete?.key !== null;
        },
    },
    actions: {
        openCreate(type: OrganisationEditType) {
            this.create = { type };
            this.edit = null;
            this.delete = null;
        },
        closeCreate() {
            this.create = null;
        },
        openEdit(type: OrganisationEditType, key: number) {
            this.edit = { type, key };
            this.create = null;
            this.delete = null;
        },
        closeEdit() {
            this.edit = null;
        },
        openDelete(type: OrganisationEditType, key: number) {
            this.delete = { type, key };
            this.create = null;
            this.edit = null;
        },
        closeDelete() {
            this.delete = null;
        },
    },
});
