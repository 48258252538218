<template>
  <div id="global-header-placeholder">
    <!-- insert your global header here -->
    <!-- end global header-->
  </div>
  <vl-page v-vl-spacer:bottom.medium>
    <vl-grid v-if="data.isLoggingOut" class="logging-out">
      <span>{{ $t('app.loggingOut') }}</span>
      <vl-loader />
    </vl-grid>
    <template v-else>
      <Navigation />
      <PbsAlert />
      <vl-main>
        <router-view />
      </vl-main>
      <PbsModal />
    </template>
  </vl-page>
  <div id="global-footer-placeholder">
    <!-- insert your global footer here -->
    <!-- end global footer -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as widgets from '@govflanders/vl-widget-client';
import { config } from './common/config/config';
import { onMounted } from 'vue';
import { stores } from './store';
import { reactive } from 'vue';
import PbsAlert from '@/modules/core/components/alerts/PbsAlert.vue';
import PbsModal from '@/modules/core/components/modals/PbsModal.vue';
import Navigation from './components/Navigation.vue';

export default defineComponent({
  components: {
    PbsAlert,
    PbsModal,
    Navigation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const mountWidgets = async () => {
      try {
        await widgets.bootstrap(config.header.widgetUrl).then((widget) => {
          if (!document.getElementById('headerWrapperElement')) {
            const headerWrapperElement = document.createElement('div');
            headerWrapperElement.id = 'headerWrapperElement';
            const parentContainer = document.getElementById('global-header-placeholder');
            widget.setMountElement(headerWrapperElement);
            widget.mount();
            parentContainer?.insertBefore(headerWrapperElement, parentContainer.childNodes[0] || null);
          }
        });
        await widgets.bootstrap(config.footer.widgetUrl).then((widget) => {
          if (!document.getElementById('footerWrapperElement')) {
            const footerWrapperElement = document.createElement('div');
            footerWrapperElement.id = 'footerWrapperElement';
            widget.setMountElement(footerWrapperElement);
            widget.mount();
            document.getElementById('global-footer-placeholder')?.appendChild(footerWrapperElement);
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('[Front-end wp-mx-widgets.js] - generateWidgets', error);
      }
    };

    const addWidgetHooks = async () => {
      widgets.capture(async (widget: any) => {
        if (widget.getPluginTypeId() === 'global_header') {
          widget.getExtension('citizen_profile.session').then(async (session: any) => {
            session.configure({
              active: stores.UserStore().isAuthenticated,
              endpoints: {
                loginRedirectUrl: '/',
                loginUrl: '/auth/login',
                logoutUrl: '/auth/logout',
                switchCapacityUrl: '/auth/switch',
              },
            });
          });

          widget.on('citizen_profile.session.logout.request', async (logoutRequest) => {
            logoutRequest.acknowledge();
            var reason = logoutRequest.getRequest().getReason();
            switch (reason) {
              case 'user':
                data.isLoggingOut = true;
                logoutRequest.accept();
                break;
              default:
                logoutRequest.reject();
                break;
            }
          });
        }
      });
    };

    onMounted(async () => {
      await mountWidgets();
      await addWidgetHooks();
    });

    const data = reactive({
      isLoggingOut: false,
    });

    return { data };
  },
});
</script>

<style lang="scss" scoped>
.logging-out {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.vl-loader {
  margin-top: 5px;
  display: block;
}
</style>
