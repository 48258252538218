<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromEntity"
        :label="t('entitylink.detail.fromEntity')"
        :placeholder="$t('entitylink.detail.fromEntity')"
        :options="fromEntityOptions"
        :custom-label="entityModelLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toEntity"
        :label="t('entitylink.detail.toEntity')"
        :placeholder="$t('entitylink.detail.toEntity')"
        :options="toEntityOptions"
        :custom-label="entityModelLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsRadioButtonField name="relatedSelection" :label="$t('entitylink.detail.relatedSelection')" />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromAttribute"
        :label="t('entitylink.detail.fromAttribute')"
        :placeholder="$t('entitylink.detail.fromAttribute')"
        :options="fromAttributeOptions"
        :mod-disabled="!values.fromEntity"
        :custom-label="entityAttributeLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toAttribute"
        :label="t('entitylink.detail.toAttribute')"
        :placeholder="$t('entitylink.detail.toAttribute')"
        :options="toAttributeOptions"
        :mod-disabled="!values.toEntity"
        :custom-label="entityAttributeLabel"
      />
    </vl-form-column>
    <vl-action-group v-vl-spacer:bottom.small>
      <vl-button icon="save" :mod-loading="entityLinkStore.reloading" mod-icon-before type="submit">
        {{ $t('general.save') }}
      </vl-button>
      <vl-button icon="cross" type="button" mod-icon-before mod-secondary mod-error @click="editStore.closeCreate">
        {{ $t('general.cancel') }}
      </vl-button>
    </vl-action-group>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { EntityAttributeDetailDto, SearchEntityModelDto } from '@/api/portal-api/clients';
import { useForm } from 'vee-validate';
import { boolean, object } from 'yup';
import { useI18n } from 'vue-i18n';
import { useProductEntityStore } from '@/modules/configuration/store/product-entity/product-entity.store';
import { useProductEntityLinkStore } from '@/modules/configuration/store/product-entity-link/product-entity-link.store';
import { useProductProcessDefinitionEditStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import PbsRadioButtonField from '@/components/forms/PbsRadioButtonField.vue';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const { t } = useI18n();

const entityStore = useProductEntityStore();
const entityLinkStore = useProductEntityLinkStore();
const editStore = useProductProcessDefinitionEditStore();

const props = defineProps({
  specificationId: {
    type: Number,
    required: true,
  },
});

const validationSchema = object({
  fromEntity: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.fromEntity') })),
  toEntity: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.toEntity') })),
  relatedSelection: boolean().required(
    t('validations.messages.required', { _field_: t('entitylink.detail.relatedSelection') }),
  ),
  fromAttribute: object().required(
    t('validations.messages.required', { _field_: t('entitylink.detail.fromAttribute') }),
  ),
  toAttribute: object().required(t('validations.messages.required', { _field_: t('entitylink.detail.toAttribute') })),
});
const form = useForm();
const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const fromEntityOptions = computed(() => {
  const key = `specification-${props.specificationId}`;
  return entityStore.items.find((x) => x.key === key)?.value.filter((x) => x.id !== values.toEntity?.id) ?? [];
});

const toEntityOptions = computed(() => {
  const key = `specification-${props.specificationId}`;
  return entityStore.items.find((x) => x.key === key)?.value.filter((x) => x.id !== values.fromEntity?.id) ?? [];
});

const fromAttributeOptions = computed(() => {
  return values.fromEntity?.model?.attributes ?? [];
});

const toAttributeOptions = computed(() => {
  return values.toEntity?.model?.attributes ?? [];
});

watch(
  fromAttributeOptions,
  () => {
    if (!fromAttributeOptions.value.find((x) => x.id === values?.fromAttribute?.id)) {
      setFieldValue('fromAttribute', null, false);
    }
  },
  { deep: true },
);

watch(
  toAttributeOptions,
  () => {
    if (!toAttributeOptions.value.find((x) => x.id === values?.toAttribute?.id)) {
      setFieldValue('toAttribute', null, false);
    }
  },
  { deep: true },
);

onMounted(async () => {
  resetForm();
});

const onSuccess = async (values) => {
  await entityLinkStore.createEntity(
    props.specificationId,
    values.fromEntity?.id,
    values.toEntity?.id,
    values.fromAttribute?.id,
    values.toAttribute?.id,
    values.relatedSelection,
  );
  editStore.closeCreate();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const entityModelLabel = (type: SearchEntityModelDto) => {
  return `${type.code} (${type.label})`;
};

const entityAttributeLabel = (type: EntityAttributeDetailDto) => {
  return `${type.code} (${type.label})`;
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
