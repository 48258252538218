import { ProcessTriggerDetailDto } from '@/api/portal-api/clients';
import { computed, ref } from 'vue';
import { object, string, mixed, boolean } from 'yup';

export const useProcessTriggerValidations = (t, detail: ProcessTriggerDetailDto = null) => {
    const timeTriggerValidationSchema = object({
        triggerType: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.triggerType') }),
        ),
        description: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.description') }),
        ),
        deliveryProcessDefinition: object().nullable(),
        schedule: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.schedule') }),
        ),
        job: string(),
        isEnabled: boolean(),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                const { deliveryProcessDefinition, job } = context.parent;
                if (!deliveryProcessDefinition && !job) {
                    return context.createError({
                        path: 'deliveryProcessDefinition',
                        message: t('processtrigger.detail.validations.jobOrDefinitionRequired'),
                    });
                }
                return true;
            },
        ),
    });

    const defaultValidationSchema = object({
        triggerType: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.triggerType') }),
        ),
        description: string().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.description') }),
        ),
        deliveryProcessDefinition: object().required(
            t('validations.messages.required', { _field_: t('processtrigger.detail.deliveryProcessDefinition') }),
        ),
        isEnabled: boolean(),
    });

    const triggerTypeRef = ref(detail?.triggerType);

    const validationSchema = computed(() => {
        const isTimeTrigger = triggerTypeRef.value?.id === 3;
        return isTimeTrigger ? timeTriggerValidationSchema : defaultValidationSchema;
    });

    return {
        validationSchema,
        triggerTypeRef,
    };
};
