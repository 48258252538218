<template>
  <div>
    <vl-region>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <vl-grid mod-stacked mod-center>
              <vl-column width="9" width-xs="12">
                <vl-title tag-name="h1">
                  {{ $t('delivery.list.title') }}
                </vl-title>
              </vl-column>
              <vl-column width="3" width-xs="12" class="vl-u-align-right">
                <vl-link mod-button mod-large to="/delivery/create">
                  {{ $t('delivery.list.action.create') }}
                </vl-link>
              </vl-column>
            </vl-grid>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
    <vl-region mod-stacked-large>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <div class="pbs-form-group">
              <vl-grid mod-stacked>
                <vl-column width="12" width-s="12" width-xs="12">
                  <vl-form-message-label for="definition" class="vl-search-filter__field__label">{{
                    $t('delivery.list.filter.definition.label')
                  }}</vl-form-message-label>
                  <vl-select
                    id="definition"
                    v-model="filter.definition"
                    name="definition"
                    mod-block
                    :placeholder-text="$t('vl-select.placeholder')"
                    @update:model-value="definitionChanged"
                  >
                    <option v-for="definition in definitions" :key="definition.id" :value="definition.id">
                      {{ getDefinitionLabel(definition) }}
                    </option>
                  </vl-select>
                </vl-column>
                <vl-column width="6" width-s="6" width-xs="12">
                  <vl-form-message-label for="supplier" class="vl-search-filter__field__label">{{
                    $t('delivery.list.filter.supplier.label')
                  }}</vl-form-message-label>
                  <vl-select
                    id="supplier"
                    v-model="filter.supplier"
                    name="supplier"
                    mod-block
                    :placeholder-text="$t('vl-select.placeholder')"
                    @update:model-value="supplierChanged"
                  >
                    <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">
                      {{ supplier.name }}
                    </option>
                  </vl-select>
                </vl-column>
                <vl-column width="3" width-s="6" width-xs="12">
                  <vl-form-message-label for="fileName" class="vl-search-filter__field__label">{{
                    $t('delivery.list.filter.fileName.label')
                  }}</vl-form-message-label>
                  <vl-input-field
                    id="fileName"
                    v-model="filter.fileName"
                    name="fileName"
                    type="search"
                    placeholder="Bestandsnaam"
                    mod-block
                    @update:model-value="fileNameChanged"
                  />
                </vl-column>
                <vl-column width="3" width-s="6" width-xs="12">
                  <vl-form-message-label for="username" class="vl-search-filter__field__label">{{
                    $t('delivery.list.filter.username.label')
                  }}</vl-form-message-label>
                  <vl-input-field
                    id="username"
                    v-model="filter.username"
                    name="username"
                    type="search"
                    placeholder="Gebruikersnaam"
                    mod-block
                    @update:model-value="usernameChanged"
                  />
                </vl-column>
                <vl-column width="6" width-s="6" width-xs="12">
                  <vl-form-message-label class="vl-search-filter__field__label">
                    {{ $t('delivery.list.filter.startExecution.label') }}</vl-form-message-label
                  >
                  <vl-grid>
                    <vl-column width="1" width-s="12">
                      <vl-form-message-label for="startExecutionFrom">Van</vl-form-message-label>
                    </vl-column>
                    <vl-column width="5" width-s="12">
                      <pbs-datepicker
                        id="startExecutionFrom"
                        :value="getDates(filter.startExecutionFrom)"
                        name="startExecutionFrom"
                        placeholder="Van"
                        @input="startExecutionFromChanged"
                      ></pbs-datepicker>
                    </vl-column>
                    <vl-column width="1" width-s="12">
                      <vl-form-message-label for="startExecutionTo">tot</vl-form-message-label>
                    </vl-column>
                    <vl-column width="5" width-s="12">
                      <pbs-datepicker
                        id="startExecutionTo"
                        :value="getDates(filter.startExecutionTo)"
                        name="startExecutionTo"
                        placeholder="Tot en met"
                        mod-inline
                        @input="startExecutionToChanged"
                      ></pbs-datepicker>
                    </vl-column>
                  </vl-grid>
                </vl-column>
                <vl-column width="3" width-s="6" width-xs="12">
                  <vl-form-message-label for="statusses" class="vl-search-filter__field__label">{{
                    $t('delivery.list.filter.status.label')
                  }}</vl-form-message-label>
                  <vl-select
                    id="statusses"
                    v-model="filter.status"
                    name="statusses"
                    mod-block
                    :placeholder-text="$t('vl-select.placeholder')"
                    @update:model-value="statusChanged"
                  >
                    <option v-for="status in statusses" :key="status.id" :value="status.id">{{ status.name }}</option>
                  </vl-select>
                </vl-column>
              </vl-grid>
            </div>
          </vl-column>
          <vl-column v-if="activeFilters && activeFilters.length">
            <vl-annotation v-vl-spacer:bottom.small mod-small>Actieve Filters:</vl-annotation>
            <div class="pbs-pill-list vl-u-display-flex vl-u-flex-wrap-wrap">
              <span v-for="activeFilter in activeFilters" :key="activeFilter.filter">
                <vl-pill
                  v-if="!!activeFilter.value"
                  v-vl-spacer:bottom.small
                  v-vl-spacer:right.small
                  mod-closable
                  @close="activeFilter.remove"
                  >{{ $t(activeFilter.filter) }}: {{ activeFilter.value }}</vl-pill
                >
              </span>
            </div>
            <span v-if="activeFilters.length">
              <vl-button type="reset" mod-secondary @click="clearFilters">Wis filters</vl-button>
            </span>
          </vl-column>
          <vl-column>
            <vl-title tag-name="h3">{{ totalcount }} resultaten</vl-title>
          </vl-column>
          <vl-column>
            <div class="vl-u-table-overflow">
              <vl-data-table mod-hover>
                <thead>
                  <tr>
                    <th scope="col">{{ $t('delivery.list.definition.label') }}</th>
                    <th scope="col">
                      {{ $t('delivery.list.fileName.label') }}
                    </th>
                    <th scope="col">{{ $t('delivery.list.supplier.label') }}</th>
                    <th scope="col">{{ $t('delivery.list.user.label') }}</th>
                    <th scope="col">{{ $t('delivery.list.startExecution.label') }}</th>
                    <th scope="col">{{ $t('delivery.list.endExecution.label') }}</th>
                    <th scope="col">{{ $t('delivery.list.feedback.label') }}</th>
                    <th scope="col">{{ $t('delivery.list.status.label') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="delivery in deliveries" :key="delivery.id">
                    <td>{{ delivery.definition }}</td>
                    <td
                      :title="delivery.fileName && delivery.fileName.length > 20 ? delivery.fileName : ''"
                      style="
                        max-width: 10em;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ delivery.fileName }}
                    </td>
                    <td>{{ delivery.supplier }}</td>
                    <td>{{ delivery.user }}</td>
                    <td>{{ dateTimeFilter(delivery.startExecution) }}</td>
                    <td>{{ dateTimeFilter(delivery.endExecution) }}</td>
                    <td>
                      <a
                        v-if="!!delivery.feedback"
                        :href="delivery.feedback"
                        class="vl-link"
                        target="_blank"
                        rel="noopener"
                        >{{ $t('delivery.list.feedback.label') }}</a
                      >
                    </td>
                    <td>{{ delivery.status }}</td>
                    <td>
                      <div class="vl-u-align-right vl-u-whitespace--nowrap">
                        <router-link class="vl-link" :to="delivery.id.toString()">{{
                          $t('delivery.list.detail.label')
                        }}</router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </vl-data-table>
            </div>
          </vl-column>
          <vl-column>
            <vl-pager mod-align="right">
              <vl-pager-bounds
                :from="itemsFrom"
                :to="itemsTo"
                :total="itemsTotalcount"
                page-label="Page"
                :prefix="$t('pager.of')"
              />
              <vl-pager-item
                v-if="parseInt(itemsFrom) > 1"
                :a11yLabel="$t('pager.prev')"
                :label="$t('pager.prev')"
                type="previous"
                @click.prevent="previous"
              />
              <vl-pager-item
                v-if="itemsTo !== itemsTotalcount"
                :a11yLabel="$t('pager.next')"
                :label="$t('pager.next')"
                type="next"
                @click.prevent="next"
              />
            </vl-pager>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { DeliveryListService, DeliveryListServiceInstance } from './DeliveryList.service';
import { filterFromQueryString, pagingFromQueryString, sortingFromQueryString, getDates } from './DeliveryList.utils';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { DeliveryProcessDefinition, DeliverySearchResult, DeliveryStatus, Supplier } from '@/api';
import { dateFilter, dateTimeFilter } from '@/filters';
import { Dictionary } from '@/types';
import { parseFormattedDateString } from '@/helpers';
import PbsDatepicker from '@/components/forms/PbsDatepicker.vue';
import { ENUM_StatusTypes } from '@/modules/configuration/common';

export default defineComponent({
  components: {
    PbsDatepicker,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const listService: DeliveryListService = DeliveryListServiceInstance;

    // lifecycle hooks
    onBeforeRouteUpdate(async (to, from, next) => {
      await listService.search(
        filterFromQueryString(to.query),
        sortingFromQueryString(to.query),
        pagingFromQueryString(to.query),
      );

      next();
    });

    onMounted(async () => {
      await listService.init();
      await listService.search(
        filterFromQueryString(route.query),
        sortingFromQueryString(route.query),
        pagingFromQueryString(route.query),
      );
    });

    // reactive data
    const data = reactive({
      loading: [],
    });

    // computed values
    const deliveries = computed(() => listService.state.results || []);
    const definitions = computed(() => listService.definitions);
    const suppliers = computed(() => listService.suppliers);
    const statusses = computed(() => listService.statusses);
    const filter = computed(() => listService.state.filter || {});
    const paging = computed(() => listService.state.paging || {});
    const sorting = computed(() => listService.state.sorting || {});
    const totalcount = computed(() => listService.state.totalcount || 0);
    const currentPage = computed(() => paging.value.pageNumber || 1);

    const totalPages = computed(() => {
      if (!totalcount.value || !paging.value.pageSize) {
        return 1;
      }
      return Math.floor(totalcount.value / paging.value.pageSize) + 1;
    });
    const itemsFrom = computed(() => {
      if (totalcount.value === 0) {
        return '0';
      }
      return ((currentPage.value - 1) * (paging.value.pageSize || 1) + 1).toString();
    });

    const itemsTo = computed(() => {
      const to = currentPage.value * (paging.value.pageSize || 1);
      if (totalcount.value < to) {
        return totalcount.value.toString();
      }
      return to.toString();
    });

    const itemsTotalcount = computed(() => (totalcount.value ? totalcount.value.toString() : '0'));

    const activeFilters = computed(() => {
      const activeFilters = [];

      for (const key in filter.value) {
        if (!(filter.value as any)[key]) {
          continue;
        }

        const obj: { value?: string | null; filter?: string | null; remove?: () => void } = {};
        switch (key) {
          case 'definition':
            obj.value = definitions.value.find(
              (p: DeliveryProcessDefinition) => p.id == filter.value.definition,
            )?.label;
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              definitionChanged();
            };
            break;
          case 'supplier':
            obj.value = suppliers.value.find((p: Supplier) => p.id == filter.value.supplier)?.name;
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              supplierChanged();
            };
            break;
          case 'fileName':
            obj.value = filter.value.fileName;
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              fileNameChanged();
            };
            break;
          case 'username':
            obj.value = filter.value.username;
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              usernameChanged();
            };
            break;
          case 'startExecutionFrom':
            obj.value = dateFilter(filter.value.startExecutionFrom);
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              startExecutionFromChanged();
            };
            break;
          case 'startExecutionTo':
            obj.value = dateFilter(filter.value.startExecutionTo);
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              startExecutionToChanged();
            };
            break;
          case 'status':
            obj.value = statusses.value.find((p: DeliveryStatus) => p.id == filter.value.status)?.name;
            obj.filter = `delivery.list.filter.${key}.label`;
            obj.remove = (): void => {
              statusChanged();
            };
            break;
          default:
            throw new Error(`Filter ${key} not found`);
        }

        activeFilters.push(obj);
      }

      return activeFilters;
    });

    // methods
    const download = async (delivery: DeliverySearchResult): Promise<void> => {
      setLoading(delivery);
      try {
        await listService.download(delivery);
      } finally {
        setLoaded(delivery);
      }
    };

    const setLoading = (delivery: DeliverySearchResult): void => {
      data.loading = [...data.loading, delivery];
    };

    const setLoaded = (delivery: DeliverySearchResult): void => {
      data.loading = data.loading.filter((d) => d !== delivery);
    };

    const isLoading = (delivery: DeliverySearchResult): boolean => {
      return data.loading.includes(delivery);
    };

    const search = (filterquery: Dictionary<string | (string | null)[]>) => {
      const { pageNumber, pageSize, ...query } = filterquery;

      router.push({
        path: route.path,
        query,
      });
    };

    const clearFilters = (): void => {
      search({});
    };

    const getDefinitionLabel = (definition: DeliveryProcessDefinition): string => {
      const statusTypes = ENUM_StatusTypes.filter((x) => x.id !== 1); // filter out active status
      const status = statusTypes.find((x) => x.id == definition.statusId);
      return status ? `${definition.label} (${status.name})` : definition.label;
    };

    const definitionChanged = (value?: number): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, definition: encodeURIComponent('' + value) };
      } else {
        delete query.definition;
      }

      search(query);
    };

    const supplierChanged = (value?: number): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, supplier: encodeURIComponent('' + value) };
      } else {
        delete query.supplier;
      }

      search(query);
    };

    const fileNameChanged = (value?: string): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, fileName: encodeURIComponent(value) };
      } else {
        delete query.fileName;
      }

      search(query);
    };

    const usernameChanged = (value?: string): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, username: encodeURIComponent(value) };
      } else {
        delete query.username;
      }

      search(query);
    };

    const startExecutionFromChanged = (value?: string[]): void => {
      let query = { ...route.query };

      if (value && value.length > 0) {
        query = { ...query, startExecutionFrom: encodeURIComponent('' + parseFormattedDateString(value[0])?.toJSON()) };
      } else {
        delete query.startExecutionFrom;
      }

      search(query);
    };

    const startExecutionToChanged = (value?: string[]): void => {
      let query = { ...route.query };

      if (value && value.length > 0) {
        query = { ...query, startExecutionTo: encodeURIComponent('' + parseFormattedDateString(value[0])?.toJSON()) };
      } else {
        delete query.startExecutionTo;
      }

      search(query);
    };

    const statusChanged = (value?: number): void => {
      let query = { ...route.query };

      if (value) {
        query = { ...query, status: encodeURIComponent('' + value) };
      } else {
        delete query.status;
      }

      search(query);
    };

    const next = (): void => {
      const newPage = currentPage.value + 1;
      if (newPage <= totalPages.value) {
        page(newPage);
      }
    };

    const previous = (): void => {
      const newPage = currentPage.value - 1;
      if (newPage > 0) {
        page(newPage);
      }
    };

    const page = (pageNumber: number | string): void => {
      let query = { ...route.query };

      query = { ...query, pageNumber: encodeURIComponent('' + pageNumber) };

      router.push({
        path: route.path,
        query,
      });
    };

    return {
      ...toRefs(data),
      dateTimeFilter,
      deliveries,
      listService,
      definitions,
      suppliers,
      statusses,
      filter,
      paging,
      sorting,
      totalcount,
      currentPage,
      totalPages,
      itemsFrom,
      itemsTo,
      itemsTotalcount,
      activeFilters,
      download,
      isLoading,
      search,
      clearFilters,
      definitionChanged,
      supplierChanged,
      fileNameChanged,
      usernameChanged,
      startExecutionFromChanged,
      startExecutionToChanged,
      statusChanged,
      next,
      previous,
      page,
      getDates,
      getDefinitionLabel,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 30px;
}
</style>
